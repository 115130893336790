import React from 'react'

type AvatarInitialProps = {
  firstName: string
  lastName: string
  className?: string
  sizeRem?: number
}

export const AvatarInitial = ({ className, firstName, lastName, sizeRem }: AvatarInitialProps): React.ReactElement => {
  const avatarSize = sizeRem ? sizeRem : 2.25
  return (
    <div
      className={`bg-primary-300 rounded-full flex justify-center items-center text-white ${className}`}
      style={{ height: avatarSize + 'rem', width: avatarSize + 'rem' }}
    >
      {firstName.toUpperCase()[0]}
      {lastName.toUpperCase()[0]}
    </div>
  )
}

export default AvatarInitial
