import React from 'react'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import { useAppState } from '../../../presenter'
import { TenantTable } from '../../molecules/TenantTable/TenantTable'

export default function Tenants() {
  const { tenants } = useAppState()

  const onCreateTenant = async () => {
    // TODO
  }

  return (
    <>
      <h2 className='text-xl font-medium mb-5'>Tenants</h2>
      <div className='flex flex-col'>
        <div className='flex flex-row flex-shrink justify-end'>
          <button
            title='Add Tenant'
            className='w-10 h-10 rounded-md flex justify-center items-center bg-teal-300 hover:bg-teal-400'
            onClick={onCreateTenant}
          >
            <PlusIcon className='text-white' />
          </button>
        </div>

        <div>
          {<TenantTable tenants={tenants} />}
        </div>
      </div>
    </>
  )
}
