// TODO: add to a context factory
const identityUrl = process.env.MASA_ACCOUNTS_URL || 'http://127.0.0.1:4455'
const appApiUrl = process.env.MASA_APPAPI_URL || 'http://127.0.0.1:4000'
const fileStoreApiUrl = process.env.MASA_FILESTORE_URL || 'http://127.0.0.1:4444'

export default {
  'accountsGraphqlUrl': identityUrl.replace(/\/+$/, '') + '/graphql',
  'appApiGraphqlUrl': appApiUrl.replace(/\/+$/, '') + '/graphql',
  'clientUrl': process.env.MASA_APPCLIENT_URL || 'http://127.0.0.1:3000',
  'dealerHelpDeskUrl': process.env.DEALER_HELP_DESK_URL || 'https://helpdesk.foliosi.com/for-service-providers/',
  'fileStoreUrl': fileStoreApiUrl.replace(/\/+$/, ''),
  'identityGraphqlUrl': identityUrl.replace(/\/+$/, '') + '/graphql',
  'identityUrl': identityUrl.replace(/\/+$/, ''),
  'indealLink': 'https://dashboard.indeal.org/',
  'mrlLink': 'https://myresourcelibrary.com/Library',
  'navBarSlackLink': 'https://join.slack.com/t/folio-collaboration/shared_invite/zt-2b9peemsz-~oZTHZkRajdMytpG7vce0g',
  'participantHelpDeskUrl': process.env.PARTICIPANT_HELP_DESK_URL
    || 'https://helpdesk.foliosi.com/for-collaborators/',
  'wordPressFolioUrl': process.env.API_URL_BASE_FOLIO || 'https://foliosi.com',
}
