import React, { ButtonHTMLAttributes } from 'react'

type Colors = 'teal' | 'grey'
type Sizes = 'sm' | 'md' | 'lg'

const colors: Record<Colors, string> = {
  grey: 'bg-grey-350 hover:bg-grey-100 text-grey-800',
  teal: 'bg-teal-300 hover:bg-teal-400 active:bg-teal-400 text-white',
}

const sizes: Record<Sizes, string> = {
  lg: 'py-4 px-6 text-lg',
  md: 'py-2 px-4 text-base',
  sm: 'py-1 px-2 text-sm',
}

const roundedSizes: Record<Sizes, string> = {
  lg: 'rounded-lg',
  md: 'rounded-md',
  sm: 'rounded-sm',
}

interface ButtonProps {
  color?: Colors
  rounded?: Sizes
  size?: Sizes
}

export const Button = ({
  className,
  color = 'teal',
  rounded = 'lg',
  size = 'md',
  ...rest
}: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      className={`
        flex items-center justify-center ${className ?? ''}
        cursor-pointer disabled:opacity-[48%]
        ${`
          ${colors[color]} ${sizes[size]} ${roundedSizes[rounded]}
        `}
      `}
      {...rest}
    />
  )
}
