import { cloneDeep, template } from 'lodash'
import { toast } from 'react-toastify'
import {
  createTasklistTemplate as gatewayCreateTasklistTemplate,
  createTaskTemplate as gatewayCreateTaskTemplate,
  deleteSectionTemplate as gatewayDeleteSectionTemplate,
  deleteTasklistTemplate as gatewayDeleteTasklistTemplates,
  deleteTaskTemplate as gatewayDeleteTaskTemplate,
  getTasklistTemplates as gatewayGetTasklistTemplates,
  updateTasklistTemplate as gatewayUpdateTasklistTemplate,
  updateTasklistTemplateIsDefault as gatewaySetTasklistTemplateDefault,
  updateTaskTemplate as gatewayUpdateTaskTemplate,
  upsertSectionTemplate as gatewayUpsertSectionTemplate,
} from '../../gateways/WorkbookGateway'
import {
  CreateTaskListTemplateInput,
  CreateTaskTemplateInput,
  SectionTemplate,
  TasklistTemplate,
  TaskTemplate,
  UpdateTasklistTemplateInput,
  UpdateTaskTemplateInput,
  UpsertSectionTemplateInput,
} from '../../generated/api'
import { Context } from '../../presenter'
import { defaultErrorHandler } from './index'

export async function createTasklistTemplate(context: Context, input: CreateTaskListTemplateInput): Promise<string> {
  try {
    const listId = await gatewayCreateTasklistTemplate(input)
    return listId
  } catch (e) {
    const err = e as Error
    toast(err.message)
    defaultErrorHandler(context, e)
    throw e
  }
}

export async function getAllTasklistTemplates(context: Context) {
  try {
    context.state.taskLists = await gatewayGetTasklistTemplates()
  } catch (e) {
    defaultErrorHandler(context, e)
  }
}

export async function deleteTasklistTemplate(context: Context, tasklistId: string) {
  try {
    const success = await gatewayDeleteTasklistTemplates(tasklistId)
    if (success) {
      return
    }
  } catch (err) {
    toast('Unable to delete tasklist template')
  }
}

export async function updateTasklistTemplate(context: Context, updateInput: UpdateTasklistTemplateInput) {
  try {
    const success = await gatewayUpdateTasklistTemplate(updateInput)
    if (success) {
      toast(`Updated task list "${updateInput.name}"`)
      return
    }
  } catch (err) {
    toast('Unable to update tasklist template')
  }
}

export async function setTasklistTemplateDefault(context: Context, input: { templateId: string; isDefault: boolean }) {
  try {
    const success = await gatewaySetTasklistTemplateDefault(input.templateId, input.isDefault)
    if (success) {
      context.state.taskLists = context.state.taskLists?.map((list) => {
        if (list.id === input.templateId) {
          list.isDefault = input.isDefault
        }
        return list
      })

      return
    }
  } catch (err) {
    toast('Unable to set taklist default settings')
  }
}

export async function manageTasklistTemplateById(context: Context, tasklistId: string) {
  const tasklist = context.state.taskLists.find((list) => list.id === tasklistId)
  if (tasklist !== undefined) {
    manageTasklistTemplate(context, tasklist)
  }
}

export async function manageTasklistTemplate(context: Context, tasklist: TasklistTemplate) {
  context.state.currentTaskList = cloneDeep(tasklist)
  context.state.currentPage = 'ManageTasklist'
}

export async function upsertSectionTemplate(
  context: Context,
  input: UpsertSectionTemplateInput,
): Promise<SectionTemplate> {
  const isCreate = input.id === undefined || input.id === ''
  try {
    const section = await gatewayUpsertSectionTemplate(input)
    toast(`Successfully ${isCreate ? 'created' : 'updated'} section`)
    return section
  } catch (err) {
    toast(`Unable to ${isCreate ? 'create' : 'edit'} section`)
    throw err
  }
}

export async function deleteSectionTemplate(
  context: Context,
  input: { tasklistId: string; sectionTemplateId: string },
) {
  try {
    await gatewayDeleteSectionTemplate(input.sectionTemplateId)
    context.state.taskLists.map((list) => {
      if (list.id === input.tasklistId) {
        list.sections = list.sections?.filter((section) => section.id !== input.sectionTemplateId)
      }
      return list
    })

    if (context.state.currentTaskList) {
      context.state.currentTaskList.sections = context.state.currentTaskList?.sections?.filter((section) =>
        section.id !== input.sectionTemplateId
      )
    }
  } catch (err) {
    toast('Unable to delete section')
    throw err
  }
}

export async function deleteTaskTemplate(
  context: Context,
  input: { tasklistId: string; sectionId: string; taskId: string },
) {
  try {
    await gatewayDeleteTaskTemplate(input.taskId)
    context.state.taskLists.map((list) => {
      if (list.id === input.tasklistId) {
        list.sections = list.sections?.map((section) => {
          if (section.id === input.sectionId) {
            section.tasks = section.tasks?.filter((task) => task.id !== input.taskId)
          }
          return section
        })
      }
      return list
    })

    if (context.state.currentTaskList) {
      context.state.currentTaskList.sections = context.state.currentTaskList?.sections?.map((section) => {
        if (section.id === input.sectionId) {
          section.tasks = section.tasks?.filter((task) => task.id !== input.taskId)
        }
        return section
      })
    }
  } catch (err) {
    toast('Unable to delete task')
    throw err
  }
}

export async function updateTaskTemplate(context: Context, input: UpdateTaskTemplateInput): Promise<TaskTemplate> {
  try {
    const task = await gatewayUpdateTaskTemplate(input)
    toast('Successfully updated task')
    return task
  } catch (err) {
    toast('Unable to update task')
    throw err
  }
}

export async function createTaskTemplate(context: Context, input: CreateTaskTemplateInput): Promise<TaskTemplate> {
  try {
    const task = await gatewayCreateTaskTemplate(input)
    toast('Successfully created task')
    return task
  } catch (err) {
    toast('Unable to created task')
    throw err
  }
}
