import * as Yup from 'yup'

const errorMessage = 'Please fill in the required field'

export const SpaceFormValidator = (needsQuantity: boolean) => {
  if (needsQuantity) {
    return Yup.object().shape({
      description: Yup.string().required(errorMessage),
      image: Yup.string().required('An image is required'),
      industry: Yup.object().shape({
        id: Yup.string().required('an industry is required'),
      }),
      items: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('An item needs to be selected'),
          quantity: Yup.number().integer().min(1, 'Quantity can not be less than 1.').required(),
        }),
      ),
      name: Yup.string().required(errorMessage),
      newItems: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('An item needs to be selected'),
          quantity: Yup.number().integer().min(1, 'Quantity can not be less than 1.').required(),
        }),
      ),
      quantity: Yup.number().integer().min(1, 'Quantity can not be less than 1.').required(),
    })
  } else {
    return Yup.object().shape({
      description: Yup.string().required(errorMessage),
      image: Yup.string().required('An image is required'),
      industry: Yup.object().shape({
        id: Yup.string().required('an industry is required'),
      }),
      items: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('An item needs to be selected'),
          quantity: Yup.number().integer().min(1, 'Quantity can not be less than 1.').required(),
        }),
      ),
      name: Yup.string().required(errorMessage),
      newItems: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('An item needs to be selected'),
          quantity: Yup.number().integer().min(1, 'Quantity can not be less than 1.').required(),
        }),
      ),
    })
  }
}
