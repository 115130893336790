import React from 'react'
import { Modal } from 'theorem-lib/src/components/molecules/Modal/Modal'
import { useActions, useAppState } from '../../../presenter'

export function GenericCancelModal() {
  const { currentModal } = useAppState()
  const { displayModalAction, displaySlideOutAction } = useActions()

  const onClose = async () => {
    displayModalAction('None')
  }

  const onDiscard = async () => {
    displayModalAction('None')
    displaySlideOutAction('None')
  }

  return (
    <Modal
      isOpen={currentModal === 'GenericCancelModal'}
      onClose={onClose}
    >
      <div className='flex flex-col justify-center items-center w-[80vw] sm:w-[558px]'>
        <div className='text-primary-100 text-xl font-semibold mb-2 text-center'>
          Are you sure you want to leave without saving?
        </div>
        <div className='text-primary-300 text-base font-normal text-center mb-6'>
          Changes you have made so far will not be saved.
        </div>
        <div className='flex flex-row'>
          <button type='button' className='button mx-2' onClick={onClose}>Keep Editing</button>
          <button
            type='button'
            className='button-no-bg-color bg-teal-300 hover:bg-teal-400 text-white mx-2'
            onClick={onDiscard}
          >
            Confirm
          </button>
        </div>
      </div>
    </Modal>
  )
}
