import { gql } from '@apollo/client'

export const GetTenants = gql `
query {
  getTenants {
    ownerId,
    name,
    subDomain,     
    details{
      id,
      code,
      tenantName,
      email,
      licenses,
      used
    }
  }
}
`

export const UpdateTenantDocument = gql `
mutation UpdateTenantInfoInput($input:UpdateTenantDetailsInput!) {
  updateTenant (input:$input){
  ownerId,
  name,
  subDomain,  
  details{
    id,
    code,
    tenantName,
    email,
    licenses,
    used
  }
 }
}
`
