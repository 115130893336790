import React, { FC } from 'react'
import HouseIcon from 'theorem-lib/src/assets/icons/room.svg'
import { Button } from 'theorem-lib/src/components/atoms/Button/Button'

interface OwnProps {
  texts: [string, string]
  onAddNew: () => void
}

const NoInfo: FC<OwnProps> = function({ texts: [plural, section], onAddNew }) {
  return (
    <div className='flex flex-1 flex-col justify-center items-center'>
      <div className='border-primary-100 rounded-full border-2 w-12 h-12 flex justify-center items-center mb-4'>
        <HouseIcon />
      </div>
      <h4 className='text-xl font-semibold text-primary-100 mb-0.5'>
        You don&apos;t have any {plural} yet
      </h4>
      <p className='mb-6 text-primary-200'>All {plural} members created will be displayed here</p>

      <Button onClick={onAddNew}>Add New {section}</Button>
    </div>
  )
}

export default NoInfo
