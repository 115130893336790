import React from 'react'
import CogIcon from 'theorem-lib/src/assets/icons/cog.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import Table, { TableColumn } from 'theorem-lib/src/components/molecules/Table/Table'
import { SpaceItemTemplate } from '../../../generated/api'
import { useActions } from '../../../presenter'

const tableColumns: TableColumn<SpaceItemTemplate>[] = [
  {
    cell: (
      { image },
    ) => (image
      ? (
        <div className='-mr-4 w-14 h-14 bg-cover bg-center bg-no-repeat' style={{ backgroundImage: `url(${image})` }}>
        </div>
      )
      : <div className='-mr-4 w-14 h-14 bg-gray-300'></div>),
    width: '88px',
  },
  {
    name: 'Name',
    selector: ({ name }) => name,
  },
  {
    cell: ({ category }) => <span>{category?.name ?? ''}</span>,
    name: 'Category',
  },
  {
    cell: ({ id }) => <ItemItemActions id={id ?? '1'} />,
    right: true,
  },
]

export const ItemList = ({ items }: { items: SpaceItemTemplate[] }) => {
  return (
    <div className='w-full'>
      <Table
        columns={tableColumns}
        data={items}
        className='w-full'
        highlightOnHover
        overrideStyles={{ rows: { style: { paddingBottom: '.75rem', paddingTop: '.75rem' } } }}
      />
    </div>
  )
}

const ItemItemActions = ({ id }: { id: string }) => {
  const { deleteItemAction, displaySlideOutAction, fetchItemTemplateById, fetchItemTemplateCategories } = useActions()

  const handleManage = async () => {
    await fetchItemTemplateById(id)
    await fetchItemTemplateCategories()
    await displaySlideOutAction('ManageItem')
  }
  const handleDelete = async () => {
    await deleteItemAction(id)
  }

  return (
    <div className='space-x-5 flex items-center'>
      <button
        className='flex items-center justify-center text-900'
        title='Manage item'
        onClick={handleManage}
      >
        <CogIcon />
      </button>
      <button
        className='flex items-center justify-center text-900'
        title='Delete item'
        onClick={handleDelete}
      >
        <TrashIcon />
      </button>
    </div>
  )
}
