import { IContext } from 'overmind'
import { GenericDeleteModalProps } from '../../components/organisms/Modals/GenericDeleteModal'
import config from '../../config'
import { setActiveScreen } from '../../helpers/setActiveScreen'
import { Context } from '../../presenter'
import { ForbiddenError, InternalServerError, UnauthenticatedError } from '../errors'
import { RouteNames } from '../router'

export function defaultErrorHandler(context: Context, err: unknown) {
  if (err instanceof UnauthenticatedError) {
    window.location.assign(`${config.identityUrl}/auth/login?url=${encodeURI(window.location.href)}`)
  } else if (err instanceof ForbiddenError) {
    context.state.currentPage = 'Forbidden'
  } else if (err instanceof InternalServerError) {
    context.state.currentPage = 'Unexpected'
  } else {
    context.state.currentPage = 'Unexpected'
  }
}

export async function redirectAction(context: Context, url: string) {
  context.effects.redirect(url)
}

export async function bootstrapStateAction(context: Context): Promise<boolean> {
  try {
    context.state.authenticatedUser = await context.effects.getAuthenticatedUser()
    if (!context.state?.authenticatedUser?.securityRoles?.includes(config.appSecurityRole)) {
      throw new ForbiddenError()
    }

    return true
  } catch (err) {
    defaultErrorHandler(context, err)
    return false
  }
}

export async function displayNotFoundAction(context: Context) {
  context.state.currentPage = 'NotFound'
}

export async function displayDashboardAction(context: Context) {
  try {
    if (!context.state.lockCurrentPageChange) {
      context.state.currentPage = 'Dashboard'
      setActiveScreen(context, RouteNames.Dashboard)
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayPreDefinedContentAction(context: IContext<Context>) {
  try {
    context.state.currentPage = 'PreDefinedContent'
    setActiveScreen(context, RouteNames.PreDefinedContent)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export function displayModalAction(
  context: Context,
  modalName:
    | 'GenericCancelModal'
    | 'None',
) {
  context.state.currentModal = modalName
}

export function displayDeleteModalAction(context: Context, deleteProps: GenericDeleteModalProps) {
  context.state.deleteModalProps = deleteProps
  context.state.currentModal = 'GenericDeleteModal'
}

export function displaySlideOutAction(
  context: Context,
  slideOutName:
    | 'ManageSpace'
    | 'CreateSpace'
    | 'CreateItem'
    | 'ManageItem'
    | 'CreateList'
    | 'AddUserSlideOut'
    | 'ManageTenantLic'
    | 'None',
) {
  context.state.currentSlideOut = slideOutName
}
