import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import TextInput from '../../atoms/Input/TextInput'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOut/SlideoutFooter'

export type InviteUser = {
  email: string
  firstName: string
}

type AddUserFormProps = {
  onSubmit: (newUser: InviteUser) => void
  onClose: () => void
  onDiscard: () => void
}

export function AddUserSlideOut(props: AddUserFormProps) {
  const { formState: { errors, isDirty }, handleSubmit, register } = useForm<InviteUser>()

  function submit(newUser: InviteUser) {
    props.onSubmit(newUser)
  }

  return (
    <SlideOut onClose={() => isDirty ? props.onDiscard() : props.onClose()} title='Invite New User'>
      <h2 className='mb-6 text-primary-200 font-normal'>Enter the email of the user you would like to invite.</h2>
      <form
        onSubmit={handleSubmit(submit)}
      >
        <div className='mb-4'>
          <TextInput
            label='email'
            labelClass='text-gray-600'
            hasAutoFocus
            {...register('email', {
              pattern: {
                message: 'Please enter a valid email address',
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              },
              required: 'This field is required.',
            })}
          />
          <span className='flex flex-grow justify-end text-error font-medium text-xs'>{errors.email?.message}</span>
        </div>
        <div className='mb-4'>
          <TextInput
            label='First Name'
            type='text'
            {...register(`firstName`, { minLength: 1, required: true })}
          />
          <span className='flex flex-grow text-error font-medium text-xs'>
            {errors.firstName !== undefined && 'First Name is required'}
          </span>
        </div>

        <SlideOutFooter>
          <button
            className={`bg-cta-200 hover:bg-cta-300 text-white p-3 rounded-lg font-semibold mr-2`}
            type='submit'
          >
            Send Invitation
          </button>
        </SlideOutFooter>
      </form>
    </SlideOut>
  )
}
