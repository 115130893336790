import { ChevronLeftIcon, ChevronUpIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import ArrowRight from 'theorem-lib/src/assets/icons/arrow-right.svg'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import TextInput from 'theorem-lib/src/components/atoms/Input/TextInput'
import { useActions, useAppState } from '../../../../../presenter'
import { ManageTasklistItem } from './manageTasklistItem'
import {
  isCreated,
  isSectionTemplate,
  isTaskTemplate,
  ManagedItemTemplate,
  sectionTemplateToManageSectionTemplate,
  tasklistTemplateToManagedItems,
} from './utils'

type ManageTaskListForm = {
  description: string
  id: string
  name: string
}

const getSectionIdForTaskIndex = (items: ManagedItemTemplate[], index: number): string => {
  for (let i = index; i >= 0; i--) {
    if (isSectionTemplate(items[i])) {
      return items[i].id ?? ''
    }
  }

  return ''
}

export const ManageTasklist = () => {
  const { currentTaskList } = useAppState()
  const {
    deleteSectionTemplate,
    deleteTaskTemplate,
    displayDeleteModalAction,
    redirectAction,
    updateTasklistTemplate,
    upsertSectionTemplate,
  } = useActions()
  const initialValues: ManageTaskListForm = {
    description: currentTaskList?.description ?? '',
    id: currentTaskList?.id ?? '',
    name: currentTaskList?.name ?? '',
  }

  const { formState: { errors }, handleSubmit, register } = useForm<ManageTaskListForm>({
    defaultValues: initialValues,
  })

  const onSubmit = async (formData: ManageTaskListForm) => {
    await updateTasklistTemplate({
      ...formData,
    })
  }

  const [items, setItems] = useState<(ManagedItemTemplate)[]>(tasklistTemplateToManagedItems(currentTaskList))
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | undefined>()

  const onCreateNewSection = () => {
    const oldLen = items.length
    setItems((oldItems) => {
      return [...oldItems, {
        __typename: 'ManagedSectionTemplate',
        name: '',
        tasklistId: currentTaskList?.id ?? '',
        tasks: [],
      }]
    })
    setSelectedItemIndex(oldLen)
  }

  const onCreateNewTask = (oldIndex: number) => {
    return async () => {
      const newItems = [...items]
      let sectionId = ''
      for (let i = oldIndex; i >= 0; i--) {
        if (isSectionTemplate(newItems[i])) {
          sectionId = newItems[i].id || ''
          if (sectionId === undefined || sectionId === '') {
            const newSection = await upsertSectionTemplate({
              id: undefined,
              name: newItems[i].name || 'New Section',
              tasklistId: currentTaskList?.id || '',
            })
            sectionId = newSection.id
            newItems[i] = sectionTemplateToManageSectionTemplate(newSection)
          }
          break
        }
      }

      newItems.splice(oldIndex + 1, 0, {
        __typename: 'ManagedTaskTemplate',
        fields: [],
        name: '',
        sectionId,
      })
      setItems(newItems)
      setSelectedItemIndex(oldIndex + 1)
    }
  }

  const onClickSection = (index: number) => {
    return () => {
      const selectedSection = items[index]
      if (selectedSection) {
        setSelectedItemIndex(index)
      }
    }
  }

  const onDeleteItem = (index: number) => {
    return () => {
      const isSection = isSectionTemplate(items[index])
      displayDeleteModalAction({
        mainText: 'Are you sure you want to delete',
        onDelete: onConfirmDeleteItem(index),
        subText: `The ${isSection ? 'Section' : 'Task'} "${items[index].name}" ${
          isSection ? 'and all of its tasks' : ''
        } will go away forever`,
      })
    }
  }

  const onConfirmDeleteItem = (index: number) => {
    return () => {
      const itemToDelete = items[index]
      if (!isCreated(itemToDelete)) {
        if (isSectionTemplate(itemToDelete)) {
          deleteSectionTemplate({ sectionTemplateId: itemToDelete.id || '', tasklistId: currentTaskList?.id || '' })
        } else if (isTaskTemplate(itemToDelete)) {
          deleteTaskTemplate({
            sectionId: getSectionIdForTaskIndex(items, index),
            taskId: itemToDelete.id ?? '',
            tasklistId: currentTaskList?.id || '',
          })
        }
      }

      let newItems = [...items]
      newItems = newItems.filter((item, idx) => {
        if (idx === index) {
          return false
        } else if (isTaskTemplate(item) && 'sectionId' in item && item.sectionId === itemToDelete.id) {
          return false
        }
        return true
      })

      const numRemovedItems = newItems.length - items.length
      if (selectedItemIndex !== undefined && selectedItemIndex >= index) {
        if (selectedItemIndex - index < numRemovedItems) {
          setSelectedItemIndex(undefined)
        } else {
          setSelectedItemIndex(selectedItemIndex - numRemovedItems)
        }
      }

      setItems(newItems)
    }
  }

  return (
    <div className='flex flex-row w-full h-full'>
      <div className='w-1/2 overflow-auto'>
        <nav
          className='text-primary-200 font-medium text-base cursor-pointer flex flex-row items-center'
          onClick={() => redirectAction('/pre-defined-content?selectedTab=workbooks')}
        >
          <ChevronLeftIcon className='w-5 mr-2' />
          <span>Workbook</span>
        </nav>
        <h1 className='text-4xl font-medium text-primary-100'>
          Manage List
        </h1>

        <form className='mt-5 px-4' onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-5 text-primary-100'>
            <TextInput
              label='Name'
              type='text'
              {...register(`name`, { minLength: 1, required: true })}
            />
            <span className='flex flex-grow text-error font-medium text-xs'>
              {errors.name !== undefined && 'Name is required'}
            </span>
          </div>

          <div className='mb-5 text-primary-100'>
            <TextInput
              label='Description'
              type='text'
              {...register(`description`, { minLength: 1, required: true })}
            />
            <span className='flex flex-grow text-error font-medium text-xs'>
              {errors.description !== undefined && 'Description is required'}
            </span>
          </div>
          <div className='w-full flex flex-row justify-end'>
            <button
              className='bg-cta-200 hover:bg-cta-300 text-white p-3 rounded-lg font-semibold mr-2'
              type='submit'
            >
              Save Details
            </button>
          </div>
        </form>

        <div className='flex flex-row justify-between mt-5 px-4'>
          <div>
            <div className='text-xl'>Tasks</div>
            <div className='text-gray-500'>Manage tasks and sections attached to this list</div>
          </div>
          <button
            onClick={onCreateNewSection}
            className='bg-cta-200 hover:bg-cta-300 text-white p-3 rounded-lg font-semibold mr-2'
          >
            Add Section
          </button>
        </div>

        {items.length > 0
          ? items.map((item, idx) => {
            const isSection = isSectionTemplate(item)
            const isTask = isTaskTemplate(item)
            const isSelected = idx === selectedItemIndex
            const header = isSection ? 'SECTION' : 'TASK'
            let colorClasses = ''
            if (isSelected) {
              colorClasses = 'bg-gray-200 rounded-l-lg text-gray-700 pl-3 pr-9'
            } else if (isSection) {
              colorClasses = 'px-3 text-white bg-gray-700 mr-6 rounded-lg'
            } else if (isTask) {
              colorClasses = 'px-3 text-gray-700 bg-white mr-6 rounded-lg border border-primary-200'
            }
            const shouldAddTaskFooter = items[idx + 1] === undefined || isSectionTemplate(items[idx + 1])

            const taskClasses = isTask ? 'ml-12' : 'ml-4'
            return (
              <div key={`tasklist_template_item_${idx}`}>
                <div
                  onClick={onClickSection(idx)}
                  className={`flex flex-row justify-between mt-5 items-center ${taskClasses} cursor-pointer relative z-0 ${colorClasses}`}
                >
                  <div className='flex flex-row'>
                    <ChevronUpIcon className='w-6 mr-2' />
                    <div>
                      <div className='text-sm text-gray-400'>{header}</div>
                      <div>
                        {item.name !== undefined && item.name !== ''
                          ? item.name
                          : (isSection ? 'New Section' : 'New Task')}
                      </div>
                    </div>
                  </div>
                  <TrashIcon className='relative z-10' onClick={onDeleteItem(idx)} />
                </div>
                {shouldAddTaskFooter && (
                  <div
                    className='flex flex-row cursor-pointer text-gray-700 justify-center items-center mt-5'
                    onClick={onCreateNewTask(idx)}
                  >
                    <PlusIcon />
                    Add Task
                  </div>
                )}
              </div>
            )
          })
          : (
            <div className='flex flex-col justify-center items-center mt-32 px-4'>
              <div className='border-primary-100 rounded-full border-2 w-12 h-12 flex justify-center items-center mb-4'>
                <div className='-rotate-45'>
                  <ArrowRight />
                </div>
              </div>
              <div className='text-xl'>Add a Section</div>
              <div className='text-gray-500'>Tasks and sections will be created and managed</div>
              <div className='text-gray-500'>on the right side of the screen</div>
            </div>
          )}
      </div>

      <div className='bg-gray-200 w-1/2 overflow-auto'>
        <ManageTasklistItem
          item={selectedItemIndex !== undefined ? items[selectedItemIndex] : undefined}
          itemIndex={selectedItemIndex}
          tasklistId={currentTaskList?.id ?? ''}
          isCreate={selectedItemIndex !== undefined && items[selectedItemIndex]
            ? isCreated(items[selectedItemIndex])
            : false}
          onDeleteItem={selectedItemIndex !== undefined
            ? onConfirmDeleteItem(selectedItemIndex)
            : () => {/* empty right pane state */}}
          onSetSelectedIndex={setSelectedItemIndex}
          setItems={setItems}
        />
      </div>
    </div>
  )
}
