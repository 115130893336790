import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import Select from 'react-select'
import CloseIcon from 'theorem-lib/src/assets/icons/close.svg'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import TextInput from 'theorem-lib/src/components/atoms/Input/TextInput'
import { customStyles } from 'theorem-lib/src/components/atoms/ReactSelectStyling/selectStyle'
import { TaskFieldInputDiscriminator } from '../../../../../../../../app/client/src/generated/api'
import { TaskFieldInput, TaskTemplate } from '../../../../../generated/api'
import { useActions } from '../../../../../presenter'
import {
  getTaskTemplate,
  isSectionTemplate,
  isTaskTemplate,
  ManagedItemTemplate,
  sectionTemplateToManageSectionTemplate,
  taskTemplateToManagedTaskTemplate,
} from './utils'

export type ManageTasklistItemProps = {
  tasklistId: string
  item?: ManagedItemTemplate
  itemIndex?: number
  isCreate: boolean
  onDeleteItem: () => void
  onSetSelectedIndex: (index: number | undefined) => void
  setItems: (modifyFunc: (items: ManagedItemTemplate[]) => ManagedItemTemplate[]) => void
}

type ManageItemForm = {
  id: string | undefined
  name: string
  tasklistId?: string
  sectionId?: string
  taskType?: TaskType
  dateLabel?: string
  textFields: { label: string }[]
  checkboxFields: { label: string }[]
  radioFields: { label: string }[]
  areaFields: { label: string }[]
}

type TaskType = 'Checkbox' | 'Date' | 'Text' | 'Radio' | 'Area'

const typeOptions = [
  {
    label: 'Text Area',
    value: 'Area',
  },
  {
    label: 'Checkboxes',
    value: 'Checkbox',
  },
  {
    label: 'Date',
    value: 'Date',
  },
  {
    label: 'Text Fields',
    value: 'Text',
  },
  {
    label: 'Radio Buttons',
    value: 'Radio',
  },
]

const getTypeOption = (value: string): { label: string; value: string } | undefined => {
  return typeOptions.find((opt) => opt.value === value)
}

const convertLabelToKey = (value: string): string => value.toLowerCase().replace(' ', '_')

const getTaskFields = (formData: ManageItemForm): TaskFieldInput[] => {
  if (formData.taskType === 'Checkbox') {
    return formData.checkboxFields.map((checkbox) => {
      return {
        label: checkbox.label,
        name: convertLabelToKey(checkbox.label),
        type: TaskFieldInputDiscriminator.Checkbox,
      }
    })
  } else if (formData.taskType === 'Date') {
    return [{
      label: formData.dateLabel,
      name: convertLabelToKey(formData.dateLabel ?? ''),
      type: TaskFieldInputDiscriminator.Date,
    }]
  } else if (formData.taskType === 'Text') {
    return formData.textFields.map((text) => {
      return {
        label: text.label,
        name: convertLabelToKey(text.label),
        type: TaskFieldInputDiscriminator.Text,
      }
    })
  } else if (formData.taskType === 'Radio') {
    return [{
      label: `${formData.name} Options`,
      name: convertLabelToKey(`${formData.name}_radio`),
      options: formData.radioFields.map((radio) => {
        return {
          label: radio.label,
          value: convertLabelToKey(radio.label),
        }
      }),
      type: TaskFieldInputDiscriminator.Radio,
    }]
  } else if (formData.taskType === 'Area') {
    return formData.areaFields.map((text) => {
      return {
        label: text.label,
        name: convertLabelToKey(text.label),
        type: TaskFieldInputDiscriminator.Area,
      }
    })
  }

  return []
}

export const ManageTasklistItem = (props: ManageTasklistItemProps) => {
  const { createTaskTemplate, updateTaskTemplate, upsertSectionTemplate } = useActions()

  const isSection = props.item ? isSectionTemplate(props.item) : false
  const isTask = props.item ? isTaskTemplate(props.item) : false
  const [currentTaskType, setCurrentTaskType] = useState<TaskType | undefined>()

  const onItemSubmit = async (formData: ManageItemForm) => {
    if (formData.sectionId === undefined || formData.sectionId === '') {
      const newSection = await upsertSectionTemplate({
        id: formData === undefined ? undefined : formData.id,
        name: formData.name,
        tasklistId: props.tasklistId,
      })

      if (props.itemIndex !== undefined) {
        const capturedIndex = props.itemIndex
        props.setItems((oldItems) => {
          const newItems = [...oldItems]
          newItems[capturedIndex] = sectionTemplateToManageSectionTemplate(newSection)
          return newItems
        })
      }
    } else {
      let newTask: TaskTemplate
      if (formData.id === undefined || formData.id === '') {
        newTask = await createTaskTemplate({
          fields: getTaskFields(formData),
          name: formData.name,
          sectionId: formData.sectionId,
        })
      } else {
        newTask = await updateTaskTemplate({
          fields: getTaskFields(formData),
          id: formData.id,
          name: formData.name,
          sectionId: formData.sectionId,
        })
      }

      if (props.itemIndex !== undefined) {
        const capturedIndex = props.itemIndex
        props.setItems((oldItems) => {
          const newItems = [...oldItems]
          newItems[capturedIndex] = taskTemplateToManagedTaskTemplate(newTask)
          return newItems
        })
      }
    }
  }

  const onDiscard = () => {
    if (props.isCreate) {
      props.onDeleteItem()
    } else {
      props.onSetSelectedIndex(undefined)
    }
  }

  const {
    control,
    formState: { errors: sectionErrors },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<ManageItemForm>({
    defaultValues: {
      id: props.item?.id,
      name: props.item?.name,
    },
  })

  const { append: areaFieldAppend, fields: areaFields, remove: areaFieldRemove, replace: areaFieldReplace } =
    useFieldArray({
      control,
      name: 'areaFields',
    })

  const onAddAreaField = () => {
    areaFieldAppend({ label: '' })
  }

  const onRemoveAreaField = (index: number) => {
    return () => {
      areaFieldRemove(index)
    }
  }

  const { append: textFieldAppend, fields: textFields, remove: textFieldRemove, replace: textFieldReplace } =
    useFieldArray({
      control,
      name: 'textFields',
    })

  const onAddTextField = () => {
    textFieldAppend({ label: '' })
  }

  const onRemoveTextField = (index: number) => {
    return () => {
      textFieldRemove(index)
    }
  }

  const { append: checkboxAppend, fields: checkboxFields, remove: checkboxRemove, replace: checkboxReplace } =
    useFieldArray({
      control,
      name: 'checkboxFields',
    })

  const onAddCheckbox = () => {
    checkboxAppend({ label: '' })
  }

  const onRemoveCheckbox = (index: number) => {
    return () => {
      checkboxRemove(index)
    }
  }

  const { append: radioAppend, fields: radioFields, remove: radioRemove, replace: radioReplace } = useFieldArray({
    control,
    name: 'radioFields',
  })

  const onAddRadio = () => {
    radioAppend({ label: '' })
  }

  const onRemoveRadio = (index: number) => {
    return () => {
      radioRemove(index)
    }
  }

  const onTypeChange = (newType: { label: string; value: string } | null) => {
    if (newType !== null) {
      const convType = newType.value as TaskType
      setValue('taskType', convType)
      setCurrentTaskType(convType)
      if (convType === 'Text' && !getValues().textFields?.length) {
        onAddTextField()
      } else if (convType === 'Radio' && !getValues().radioFields?.length) {
        onAddRadio()
      } else if (convType === 'Checkbox' && !getValues().checkboxFields?.length) {
        onAddCheckbox()
      } else if (convType === 'Area' && !getValues().areaFields?.length) {
        onAddAreaField()
      }
    }
  }

  useEffect(() => {
    reset()
    setCurrentTaskType(undefined)
    setValue('id', props.item?.id)
    setValue('name', props.item?.name ?? '')
    setValue('tasklistId', props.tasklistId)

    if (props.item === undefined || isSectionTemplate(props.item)) {
      return
    }

    const task = getTaskTemplate(props.item)
    setValue('sectionId', task?.sectionId)

    let taskType = undefined
    const labels: { label: string }[] = []

    task?.fields?.forEach((field) => {
      if (field.__typename === 'CheckboxField') {
        taskType = 'Checkbox'
        labels.push({ label: field.label })
      } else if (field.__typename === 'DateField') {
        taskType = 'Date'
        labels.push({ label: field.label })
      } else if (field.__typename === 'AreaField') {
        taskType = 'Area'
        labels.push({ label: field.label })
      } else if (field.__typename === 'TextField') {
        taskType = 'Text'
        labels.push({ label: field.label })
      } else if (field.__typename === 'RadioField') {
        taskType = 'Radio'
        field.options.forEach((opt) => {
          labels.push({ label: opt.label })
        })
      }
    })

    setValue('dateLabel', undefined)
    setValue('taskType', taskType)

    if (labels.length > 0) {
      if (taskType === 'Text') {
        textFieldReplace(labels)
      } else if (taskType === 'Area') {
        areaFieldReplace(labels)
      } else if (taskType === 'Date') {
        setValue('dateLabel', labels[0].label)
      } else if (taskType === 'Checkbox') {
        checkboxReplace(labels)
      } else if (taskType === 'Radio') {
        radioReplace(labels)
      }
    }
    setCurrentTaskType(taskType)
  }, [props.item, setValue, setCurrentTaskType, reset])

  return (
    <div className='w-full h-full'>
      {props.item !== undefined
        ? (
          <>
            <h2 className='text-3xl font-bold text-primary-100 mt-8 ml-5 mb-5 flex flew-row justify-between items-center pr-4'>
              <div>
                {`${props.isCreate ? 'Create' : 'Manage'} ${isSection ? 'Section' : 'Task'}`}
              </div>
              <div>
                <CloseIcon className='cursor-pointer' onClick={onDiscard} />
              </div>
            </h2>

            <form onSubmit={handleSubmit(onItemSubmit)}>
              <div className='mb-5 text-primary-100 px-5'>
                <TextInput
                  label={`${isSection ? 'Section' : 'Task'} Name`}
                  type='text'
                  inputClass='bg-gray-200'
                  hasAutoFocus
                  {...register(`name`, { required: true })}
                />
                <span className='flex flex-grow text-error font-medium text-xs'>
                  {sectionErrors.name !== undefined && `${isSection ? 'Section' : 'Task'} name is required`}
                </span>

                {isTask && (
                  <>
                    <div className='mt-5 border-b border-primary-400'>
                      {currentTaskType !== undefined
                        && <label className='text-xs pl-2' htmlFor='task-type-select'>Question type</label>}
                      <Select
                        id='task-type-select'
                        styles={{
                          ...customStyles,
                          control: (base: any, state: { isFocused: any }) => ({
                            ...base,
                            background: '#E5E7EB',
                            border: state.isFocused ? 0 : 0,
                            boxShadow: state.isFocused ? 0 : 0,
                            color: '#35697D',
                            height: 40,
                            width: '100%',
                          }),
                          placeholder: (base: any) => ({
                            ...base,
                            color: '#35697D',
                          }),
                        }}
                        options={typeOptions}
                        onChange={onTypeChange}
                        placeholder='Question type'
                        value={currentTaskType === undefined ? null : getTypeOption(currentTaskType)}
                      />
                    </div>

                    {currentTaskType === 'Date' && (
                      <div className='mt-5 text-primary-100'>
                        <TextInput
                          label='Label'
                          type='text'
                          inputClass='bg-gray-200'
                          {...register(`dateLabel`, { required: true })}
                        />
                        <span className='flex flex-grow text-error font-medium text-xs'>
                          {sectionErrors.dateLabel !== undefined && 'A label is required'}
                        </span>
                      </div>
                    )}

                    {currentTaskType === 'Area' && (
                      <>
                        {areaFields.map((field, index) => (
                          <div key={field.id} className='mt-8 text-primary-100 pl-2 flex flex-row items-center'>
                            <TextInput
                              label={`Input Label ${index + 1}`}
                              type='text'
                              inputClass='bg-gray-200'
                              {...register(`areaFields.${index}.label`)}
                            />
                            {index > 0 && (
                              <TrashIcon onClick={onRemoveAreaField(index)} className='cursor-pointer ml-2' />
                            )}
                          </div>
                        ))}

                        <div
                          className='flex flex-row mt-5 text-primary-100 pl-2 cursor-pointer'
                          onClick={onAddAreaField}
                        >
                          <div className='text-teal-300'>
                            <PlusIcon />
                          </div>
                          <div>
                            Add new
                          </div>
                        </div>
                      </>
                    )}

                    {currentTaskType === 'Text' && (
                      <>
                        {textFields.map((field, index) => (
                          <div key={field.id} className='mt-8 text-primary-100 pl-2 flex flex-row items-center'>
                            <TextInput
                              label={`Input Label ${index + 1}`}
                              type='text'
                              inputClass='bg-gray-200'
                              {...register(`textFields.${index}.label`)}
                            />
                            {index > 0 && (
                              <TrashIcon onClick={onRemoveTextField(index)} className='cursor-pointer ml-2' />
                            )}
                          </div>
                        ))}

                        <div
                          className='flex flex-row mt-5 text-primary-100 pl-2 cursor-pointer'
                          onClick={onAddTextField}
                        >
                          <div className='text-teal-300'>
                            <PlusIcon />
                          </div>
                          <div>
                            Add new
                          </div>
                        </div>
                      </>
                    )}

                    {currentTaskType === 'Checkbox' && (
                      <>
                        {checkboxFields.map((field, index) => (
                          <div key={field.id} className='mt-8 text-primary-100 pl-2 flex flex-row items-center'>
                            <TextInput
                              label={`Option ${index + 1}`}
                              type='text'
                              inputClass='bg-gray-200'
                              {...register(`checkboxFields.${index}.label`)}
                            />
                            {index > 0 && (
                              <TrashIcon onClick={onRemoveCheckbox(index)} className='cursor-pointer ml-2' />
                            )}
                          </div>
                        ))}

                        <div
                          className='flex flex-row mt-5 text-primary-100 pl-2 cursor-pointer'
                          onClick={onAddCheckbox}
                        >
                          <div className='text-teal-300'>
                            <PlusIcon />
                          </div>
                          <div>
                            Add new
                          </div>
                        </div>
                      </>
                    )}

                    {currentTaskType === 'Radio' && (
                      <>
                        {radioFields.map((field, index) => (
                          <div key={field.id} className='mt-8 text-primary-100 pl-2 flex flex-row items-center'>
                            <TextInput
                              label={`Option ${index + 1}`}
                              type='text'
                              inputClass='bg-gray-200'
                              {...register(`radioFields.${index}.label`)}
                            />
                            {index > 0 && <TrashIcon onClick={onRemoveRadio(index)} className='cursor-pointer ml-2' />}
                          </div>
                        ))}

                        <div className='flex flex-row mt-5 text-primary-100 pl-2 cursor-pointer' onClick={onAddRadio}>
                          <div className='text-teal-300'>
                            <PlusIcon />
                          </div>
                          <div>
                            Add new
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className='flex flex-row justify-end mr-4 mb-4'>
                <button
                  type='submit'
                  className='bg-cta-200 hover:bg-cta-300 text-white p-3 rounded-lg font-semibold'
                >
                  {props.isCreate ? 'Create' : 'Save'}
                </button>
              </div>
            </form>
          </>
        )
        : (
          <div className='h-full flex flex-row justify-center items-center text-gray-500'>
            Selected task or section will appear here
          </div>
        )}
    </div>
  )
}
