import { IContext } from 'overmind'
import { setActiveScreen } from '../../helpers/setActiveScreen'
import { Context } from '../../presenter'
import { RouteNames } from '../router'
import { defaultErrorHandler } from './index'

export async function getTenantsManagementAction(context: IContext<Context>) {
  try {
    context.state.currentPage = 'Loading'
    context.state.tenants = await context.effects.getTenants()
    context.state.currentPage = 'Tenants'
    setActiveScreen(context, RouteNames.Tenants)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
