import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import React, { CSSProperties } from 'react'

type CardProps = {
  children: React.ReactNode
  className?: string
  onClick?: () => void
  style?: CSSProperties
  onExpand?: () => void
  isExpanded?: boolean
}

export const Card = ({ children, className, isExpanded, onClick, onExpand, style }: CardProps): React.ReactElement => (
  <div className={`flex flex-row shadow-md rounded-lg bg-white ${className}`} onClick={onClick} style={style}>
    {!!onExpand && (
      <div className='mr-4 pt-6'>
        {isExpanded
          ? <ChevronUpIcon className='w-6 h-6 cursor-pointer' onClick={onExpand} />
          : <ChevronDownIcon className='w-6 h-6 cursor-pointer' onClick={onExpand} />}
      </div>
    )}
    <div className='w-full'>
      {children}
    </div>
  </div>
)

export default Card
