import React from 'react'
import { useAppState } from '../../presenter'

export function Dashboard() {
  const { authenticatedUser } = useAppState()

  return (
    <div className='flex flex-col'>
      <h2 className='text-xl font-medium mb-5'>Dashboard</h2>

      <div className='bg-slate-50'>
        <h2 className='text-xl font-medium text-primary-100'>
          <p>
            Hello, {authenticatedUser.firstName} {authenticatedUser.lastName}!
          </p>
        </h2>
      </div>
    </div>
  )
}
