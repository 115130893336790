import { Provider } from 'overmind-react'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { CurrentPage } from './components/CurrentPage'
import { overmindApp } from './presenter'
import { initializeRouter } from './presenter/router'
;(async function() {
  if (await overmindApp.actions.bootstrapStateAction()) {
    initializeRouter(overmindApp)
  }
})()

ReactDOM.render(
  <React.StrictMode>
    <Provider value={overmindApp}>
      <main>
        <CurrentPage />
      </main>
    </Provider>
  </React.StrictMode>,
  window.document.querySelector('#app'),
)
