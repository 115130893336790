import { gql } from '@apollo/client'

export const CreateTasklistTemplateDocument = gql `
mutation CreateTasklistTemplate($input: CreateTaskListTemplateInput!) {
    createTasklistTemplate(input: $input)
}
`

export const GetAllTasklistTemplatesDocument = gql `
query {
    getTasklistTemplates {
      id,
      name,
      isVisible,
      isVisibleChangedOn,
      description,
      isDefault,
      isDefaultChangedOn,
      sections {
        id,
        name,
        createdOn,
        createdBy,
        modifiedBy,
        modifiedOn,
        createUser {
          avatarUrl,
          businessRole,
          firstName,
          id,
          lastName,
        },
        modifyUser {
          avatarUrl,
          businessRole,
          firstName,
          id,
          lastName,
        },
        tasks {
          id,
          name,
          sectionId,
          fields {
            __typename
            ... on RadioField {
              name,
              type,
              options {
                label,
              }
            }
            ... on TextField {
              name,
              type,
              label
            }
            ... on AreaField {
              name,
              type,
              label
            }
            ... on DateField {
              name,
              type,
              label
            }
            ... on CheckboxField {
              name,
              type,
              label,
            }
          }
        }
      }
    }
}
`

export const DeleteTasklistTemplateDocument = gql `
mutation DeleteTasklistTemplate($id: ID!) {
  deleteTasklistTemplate(id: $id)
}
`

export const UpdateTasklistTemplateDocument = gql `
mutation UpdateTasklistTemplate($input: UpdateTasklistTemplateInput!) {
  updateTasklistTemplate(input: $input)
}
`

export const UpdateTaskListTemplateIsDefaultDocument = gql `
mutation UpdateTasklistTemplateIsDefault($templateId: String!, $isDefault: Boolean!) {
  updateIsProjectDefault(tasklistTemplateId: $templateId, isDefault: $isDefault)
}
`

export const UpsertSectionTemplateDocument = gql `
mutation UpsertSectionTemplate($input: UpsertSectionTemplateInput!) {
  upsertSectionTemplate(input: $input) {
    id,
    name,
    createdOn,
    createdBy,
    modifiedBy,
    modifiedOn,
    createUser {
      avatarUrl,
      businessRole,
      firstName,
      id,
      lastName,
    },
    modifyUser {
      avatarUrl,
      businessRole,
      firstName,
      id,
      lastName,
    },
    tasks {
      id,
      name,
      sectionId,
      fields {
        __typename
        ... on RadioField {
          name,
          type,
          options {
            label,
          }
        }
        ... on DateField {
          name,
          type,
          label
        }
        ... on TextField {
          name,
          type,
          label
        }
        ... on AreaField {
              name,
              type,
              label
            }
        ... on CheckboxField {
          name,
          type,
          label,
        }
      }
    }
  }
}
`

export const DeleteSectionTemplateDocument = gql `
mutation DeleteSectionTemplate($id: ID!) {
  deleteSectionTemplate(id: $id)
}
`

export const DeleteTaskTemplateDocument = gql `
mutation DeleteTaskTemplate($id: ID!) {
  deleteTaskTemplate(id: $id)
}
`
export const UpdateTaskTemplateDocument = gql `
mutation UpdateTaskTemplate($input: UpdateTaskTemplateInput!) {
  updateTaskTemplate(input: $input) {
    id,
    name,
    sectionId,
    fields {
      __typename
      ... on RadioField {
        name,
        type,
        options {
          label,
        }
      }
      ... on TextField {
        name,
        type,
        label
      }
      ... on AreaField {
        name,
        type,
        label
      }
      ... on DateField {
        name,
        type,
        label
      }
      ... on CheckboxField {
        name,
        type,
        label,
      }
    }
  }
}
`

export const CreateTaskTemplateDocument = gql `
mutation CreateTaskTemplate($input: CreateTaskTemplateInput!) {
  createTaskTemplate(input: $input) {
    id,
    name,
    sectionId,
    fields {
      __typename
      ... on RadioField {
        name,
        type,
        options {
          label,
        }
      }
      ... on TextField {
        name,
        type,
        label
      }
      ... on DateField {
        name,
        type,
        label
      }
      ... on AreaField {
              name,
              type,
              label
            }
      ... on CheckboxField {
        name,
        type,
        label,
      }
    }
  }
}
`
