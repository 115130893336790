import * as React from 'react'
import ReactModal from 'react-modal'

type ModalProps = {
  children: React.ReactNode
  isOpen: boolean
  onClose: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void
  contentStyle?: {
    backgroundColor?: string
    width?: string
  }
  overlayColor?: string
  shouldCloseOnEsc?: boolean
}

export function Modal(props: ModalProps): React.ReactElement {
  const modalStyles = {
    content: {
      backgroundColor: props.contentStyle?.backgroundColor || '#FFFFFF',
      border: 'none',
      borderRadius: 8,
      bottom: 'auto',
      left: '50%',
      marginRight: '-50%',
      overflow: 'visible',
      right: 'auto',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: props.contentStyle?.width || 'max-content',
    },
    overlay: {
      backgroundColor: props.overlayColor || 'rgba(20, 53, 69, 0.5)',
      zIndex: 150,
    },
  }

  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      style={modalStyles}
      ariaHideApp={false}
      shouldCloseOnEsc={props.shouldCloseOnEsc}
    >
      <div className='relative'>
        <div
          onClick={props.onClose}
          aria-hidden='true'
          className='absolute cursor-pointer rounded-lg flex justify-center items-center h-10 w-10 bg-grey-400'
          style={{ right: -20, top: -65 }}
        >
          <div className='text-primary-100 text-xl'>&times;</div>
        </div>
        {props.children}
      </div>
    </ReactModal>
  )
}
