import React from 'react'
import { GenericDeleteModal as LibModal } from 'theorem-lib/src/components/molecules/Modal/GenericDeleteModal'
import { useActions, useAppState } from '../../../presenter'

export type GenericDeleteModalProps = {
  mainText?: string
  subText?: string
  onDelete: () => void
}

export function GenericDeleteModal() {
  const { deleteModalProps } = useAppState()
  const { displayModalAction } = useActions()

  if (deleteModalProps === undefined) {
    displayModalAction('None')
    return <></>
  }

  const onDelete = async () => {
    deleteModalProps.onDelete()
    displayModalAction('None')
  }

  const onCancel = async () => {
    displayModalAction('None')
  }

  return (
    <LibModal
      mainText={deleteModalProps.mainText}
      subText={deleteModalProps.subText}
      onCancel={onCancel}
      onDelete={onDelete}
    />
  )
}
