import React, { FC, useCallback, useEffect, useState } from 'react'

type Colors = 'pink' | 'teal'

const tabColors: Record<Colors, string> = {
  pink: 'cta-200',
  teal: 'teal-300',
}

interface TabLink {
  label: string
  accessor: string
  Content?: JSX.Element
}

interface TabNavProps {
  onTabSelection?: (tab: TabLink) => void
  activeTab?: string
  defaultTab?: string
  tabs: TabLink[]
  className?: string
  color?: Colors
}

interface ActiveTabLineProps {
  isActive: boolean
  color: Colors
}

const ActiveTabLine: FC<ActiveTabLineProps> = ({ color, isActive }) => (
  <div className={`h-1 w-[80%] mt-1 rounded-t-lg ${isActive ? `bg-${tabColors[color]}` : ''}`} />
)

const TabNav: FC<TabNavProps> = function({
  activeTab,
  className,
  color = 'pink',
  defaultTab,
  onTabSelection,
  tabs,
}) {
  const [_activeTab, setActiveTab] = useState(defaultTab)

  useEffect(() => {
    if (activeTab && activeTab !== _activeTab) {
      setActiveTab(activeTab)
    }
  }, [activeTab])

  const handleOnTabSelection = useCallback(
    (tab: TabLink) => {
      onTabSelection && onTabSelection(tab)
      setActiveTab(tab.accessor)
    },
    [onTabSelection, _activeTab],
  )

  return (
    <nav
      className={`flex flex-row flex-shrink-1 text-primary-200 font-semibold text-base border-b ${className}`}
    >
      {tabs.map((tab, index) => {
        const isActive = _activeTab ? _activeTab === tab.accessor : index === 0

        return (
          <div
            key={tab.accessor}
            className={`mx-5 cursor-pointer flex flex-col items-center ${isActive ? `text-${tabColors[color]}` : ''}`}
            onClick={() => handleOnTabSelection(tab)}
          >
            <span>{tab.label}</span>
            <ActiveTabLine isActive={isActive} color={color} />
          </div>
        )
      })}
    </nav>
  )
}

export default TabNav
export type { TabLink }
