import merge from 'lodash/merge'
import React from 'react'
import DataTable, { TableProps as DataTableProps, TableStyles } from 'react-data-table-component'
export type { TableColumn } from 'react-data-table-component'

const customStyles: TableStyles = {
  headCells: {
    style: {
      fontSize: '.875rem',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
  },
  headRow: {
    style: {
      borderBottom: 'none',
      paddingBottom: '.75rem',
      paddingTop: '.75rem',
    },
  },
  rows: {
    style: {
      borderBottom: '1px solid #CFDBE0', // gray-300
      fontSize: '1rem',
      paddingBottom: '1.125rem',
      paddingTop: '1.125rem',
    },
  },
  tableWrapper: {
    style: {
      // Makes the table fit in the available width instead of showing
      // a horizontal scroll bar.
      tableLayout: 'fixed',
    },
  },
}

const highlightOnHoverAddendum: TableStyles = {
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: '#F5F5F5',
    },
  },
}

interface TableProps<T> extends DataTableProps<T> {
  overrideStyles?: TableStyles
}

function Table<T>(props: TableProps<T>) {
  const { highlightOnHover, overrideStyles, ...rest } = props

  const updatedStyles = merge({}, customStyles, overrideStyles)
  if (highlightOnHover) {
    merge(updatedStyles, highlightOnHoverAddendum)
  }
  if (overrideStyles !== undefined) {
    merge(updatedStyles, overrideStyles)
  }

  return <DataTable customStyles={updatedStyles} {...rest} />
}

export default Table
