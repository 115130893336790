import { ApolloClient, ApolloError, InMemoryCache, ServerError } from '@apollo/client'
import { UnauthenticatedError, UnexpectedError } from '../presenter/errors'

export function isApolloError(err: unknown | ApolloError): err is ApolloError {
  return (err as ApolloError).graphQLErrors !== undefined
}

export function isServerError(err: unknown | ServerError): err is ServerError {
  return (err as ServerError).name == 'ServerError'
}

export function graphqlErrorHandler(err: unknown | ApolloError) {
  if (isApolloError(err)) {
    const networkError = err.networkError
    if (networkError && isServerError(networkError)) {
      if (networkError.statusCode == 401) throw new UnauthenticatedError()
      if (networkError.statusCode == 404) throw new UnexpectedError()
    }
  }
}

export const createApolloClient = (uri: string) =>
  new ApolloClient({
    cache: new InMemoryCache(),
    credentials: 'include',
    uri,
  })
