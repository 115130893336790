// TODO: add to a context factory
const identityUrl = process.env.MASA_ACCOUNTS_URL || 'http://127.0.0.1:4455'
const theoremUrl = process.env.MASA_ADMINAPI_URL || 'http://127.0.0.1:5000'
const fileStoreApiUrl = process.env.MASA_FILESTORE_URL || 'http://127.0.0.1:4444'

export default {
  'appSecurityRole': 'TheoremAdmin',
  'clientUrl': process.env.MASA_ADMINCLIENT_URL || 'http://127.0.0.1:3001',
  'fileStoreUrl': fileStoreApiUrl.replace(/\/+$/, ''),
  'graphqlUrl': theoremUrl.replace(/\/+$/, '') + '/graphql' || 'http://127.0.0.1:5000/graphql',
  'identityGraphqlUrl': identityUrl.replace(/\/+$/, '') + '/graphql',
  'identityUrl': identityUrl.replace(/\/+$/, ''),
  'theoremGraphqlUrl': theoremUrl.replace(/\/+$/, '') + '/graphql',
}
