import React, { useCallback } from 'react'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import { useActions, useAppState } from '../../../../presenter'
import { SpaceList } from '../../../molecules/SpaceList'
import NoInfo from '../NoInfo/NoInfo'

export function Spaces() {
  const { displaySlideOutAction, fetchIndustries, fetchItemTemplates } = useActions()
  const { spaceTemplates } = useAppState()

  const onCreateSpace = useCallback(async () => {
    await fetchIndustries()
    await fetchItemTemplates()
    displaySlideOutAction('CreateSpace')
  }, [displaySlideOutAction])

  const formattedSpaces = spaceTemplates.map((space) => {
    return {
      id: space.id,
      industry: space.industry?.name || '',
      name: space.name,
    }
  })

  return (
    <>
      <div className='flex flex-row flex-shrink justify-end'>
        <button
          title='Add Space'
          className='w-10 h-10 rounded-md flex justify-center items-center bg-teal-300 hover:bg-teal-400'
          onClick={onCreateSpace}
        >
          <PlusIcon className='text-white' />
        </button>
      </div>

      <div className='flex grow'>
        {spaceTemplates.length > 0
          ? <SpaceList spaces={formattedSpaces} />
          : <NoInfo texts={['spaces', 'Space']} onAddNew={onCreateSpace} />}
      </div>
    </>
  )
}
