import { derived } from 'overmind'
import { SidebarIcons } from 'theorem-lib/src/entities/enums/SidebarIcons'
import { LibNavOption } from 'theorem-lib/src/entities/types/NavOption'
import { searchUsers } from 'theorem-lib/src/helpers/userHelper'
import { GenericDeleteModalProps } from '../components/organisms/Modals/GenericDeleteModal'

import {
  Industry,
  ItemCategory,
  SecurityRole,
  SpaceItemTemplate,
  SpaceTemplate,
  Status,
  TasklistTemplate,
  Tenant,
  User,
} from '../generated/api'
import { RouteNames } from './router'

export type CoreState = {
  authenticatedUser: User
  currentPage:
    | 'Dashboard'
    | 'PreDefinedContent'
    | 'Tenants'
    | 'Forbidden'
    | 'Loading'
    | 'NotFound'
    | 'Unexpected'
    | 'UserEdit'
    | 'UserManagement'
    | 'ManageTasklist'
  currentModal: 'None' | 'GenericCancelModal' | 'GenericDeleteModal'
  deleteModalProps: GenericDeleteModalProps | undefined

  lockCurrentPageChange: boolean
  currentSlideOut:
    | 'ManageSpace'
    | 'CreateSpace'
    | 'CreateItem'
    | 'ManageItem'
    | 'CreateList'
    | 'AddUserSlideOut'
    | 'ManageTenantLic'
    | 'None'
  isAdmin: boolean
  navOptions: LibNavOption[]
  validationErrors: Map<string, string>
}

export const coreState: CoreState = {
  authenticatedUser: {
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    securityRoles: [],
    status: Status.Pending,
  },
  currentModal: 'None',
  currentPage: 'Loading',
  currentSlideOut: 'None',
  deleteModalProps: undefined,
  isAdmin: derived((coreState: CoreState) =>
    Boolean(
      coreState.authenticatedUser.securityRoles?.find(sr => sr === SecurityRole.TheoremAdmin),
    )
  ),
  lockCurrentPageChange: false,
  navOptions: [
    { current: false, href: '/', icon: SidebarIcons.Home, name: RouteNames.Dashboard },
    {
      current: false,
      href: '/pre-defined-content',
      icon: SidebarIcons.Claim,
      name: RouteNames.PreDefinedContent,
    },
    {
      current: false,
      href: '/tenants/management',
      icon: SidebarIcons.Tenants,
      name: RouteNames.Tenants,
    },
    { current: false, href: '/users/management', icon: SidebarIcons.Cog, name: 'Admin' },
    { current: false, href: '/users/edit', icon: SidebarIcons.Cog, name: 'Profile' },
  ],
  validationErrors: new Map<string, string>(),
}

export type UserState = {
  filteredUsers: User[]
  userEdit: User
  userSearchString: string
  users: User[]
}

export const userState: UserState = {
  filteredUsers: derived((userState: UserState) => searchUsers(userState.userSearchString, userState.users)),
  userEdit: {
    email: '',
    id: '',
    securityRoles: [],
    status: Status.Pending,
  },
  userSearchString: '',
  users: [],
}

export type SpaceState = {
  spaceTemplates: SpaceTemplate[]
  selectedSpaceTemplate: SpaceTemplate | undefined
  itemTemplates: SpaceItemTemplate[]
  selectedItemTemplate: SpaceItemTemplate | undefined
  itemCategories: ItemCategory[]
  industryList: Industry[]
}

export const spaceState: SpaceState = {
  industryList: [],
  itemCategories: [],
  itemTemplates: [],
  selectedItemTemplate: undefined,
  selectedSpaceTemplate: undefined,
  spaceTemplates: [],
}

export type WorkbookState = {
  currentTaskList: TasklistTemplate | undefined
  taskLists: TasklistTemplate[]
}

export const workbookState: WorkbookState = {
  currentTaskList: undefined,
  taskLists: [],
}

export type TenantState = {
  tenants: Tenant[]
  selectedTenant: Tenant | undefined
}

export const tenantState: TenantState = {
  selectedTenant: undefined,
  tenants: [],
}
