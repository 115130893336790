import { toast } from 'react-toastify'
import {
  createItemTemplate as gatewayCreateItemTemplate,
  createSpaceTemplate as gatewayCreateSpaceTemplate,
  deleteItemTemplate,
  deleteSpaceTemplate,
  fetchIndustries as gatewayFetchIndustries,
  fetchItemTemplateById as gatewayFetchItemTemplateById,
  fetchItemTemplateCategories as gatewayFetchItemTemplateCategories,
  fetchSpaceById,
  getItemTemplates,
  getSpaceTemplates,
  updateItemTemplate as gatewayUpdateItemTemplate,
  updateSpaceTemplate as gatewayUpdateSpaceTemplate,
} from '../../gateways/SpacesGateway'
import {
  CreateItemTemplateInput,
  CreateSpaceTemplateInput,
  SpaceTemplateItemInput,
  UpdateItemTemplateInput,
  UpdateSpaceTemplateInput,
} from '../../generated/api'
import { Context } from '../../presenter'
import { defaultErrorHandler } from './index'

export async function fetchSpaceTemplateById(context: Context, spaceTemplateId: string) {
  try {
    context.state.selectedSpaceTemplate = await fetchSpaceById(spaceTemplateId)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function fetchItemTemplates(context: Context) {
  try {
    context.state.itemTemplates = await getItemTemplates()
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function resetCurrentSpaceTemplate(context: Context) {
  context.state.selectedSpaceTemplate = undefined
}

export async function createSpaceTemplate(
  context: Context,
  input: { space: CreateSpaceTemplateInput; items: SpaceTemplateItemInput[] },
) {
  try {
    const spaceTemplateId = await gatewayCreateSpaceTemplate(input.space)
    await updateSpaceTemplateAction(context, {
      ...input.space,
      id: spaceTemplateId,
      items: input.items,
    })
    await getSpaceTemplatesAction(context)
  } catch (e) {
    toast((e as Error).message)
    throw e
  }
}

export async function fetchItemTemplateById(context: Context, templateId: string) {
  const itemTemplate = await gatewayFetchItemTemplateById(templateId)
  context.state.selectedItemTemplate = itemTemplate
}

export function resetSelectedItemTemplate(context: Context) {
  context.state.selectedItemTemplate = undefined
}

export async function fetchItemTemplateCategories(context: Context) {
  context.state.itemCategories = await gatewayFetchItemTemplateCategories()
}

export async function createItemTemplate(context: Context, input: CreateItemTemplateInput) {
  try {
    await gatewayCreateItemTemplate(input)
    await fetchItemTemplates(context)
  } catch (e) {
    const err = e as Error
    toast(err.message)
    throw e
  }
}

export async function fetchIndustries(context: Context) {
  const industries = await gatewayFetchIndustries()
  context.state.industryList = industries
}

export async function deleteSpaceAction(context: Context, spaceDeleteId: string) {
  try {
    await deleteSpaceTemplate(spaceDeleteId)
    await getSpaceTemplatesAction(context)
    toast('Space deleted successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function deleteItemAction(context: Context, itemDeleteId: string) {
  try {
    await deleteItemTemplate(itemDeleteId)
    context.state.itemTemplates = await getItemTemplates()
    toast('Item deleted successfully!')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function resetActiveSpaceTemplate(context: Context) {
  context.state.selectedSpaceTemplate = undefined
}

export async function updateSpaceTemplateAction(context: Context, input: UpdateSpaceTemplateInput) {
  await gatewayUpdateSpaceTemplate(input)
  await getSpaceTemplatesAction(context)
}

export async function getSpaceTemplateByIdAction(context: Context, spaceTemplateId: string) {
  try {
    context.state.selectedSpaceTemplate = await fetchSpaceById(spaceTemplateId)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function getSpaceTemplatesAction(context: Context) {
  try {
    context.state.spaceTemplates = await getSpaceTemplates()
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function getItemTemplatesAction(context: Context) {
  try {
    context.state.itemTemplates = await getItemTemplates()
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateItemTemplate(context: Context, input: UpdateItemTemplateInput) {
  try {
    await gatewayUpdateItemTemplate(input)
    await getItemTemplatesAction(context)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
