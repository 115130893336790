import { yupResolver } from '@hookform/resolvers/yup'
import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import Select from 'react-select'
import TrashIcon from '../../../assets/icons/trash.svg'
import UploadIcon from '../../../assets/icons/upload.svg'
import { LibItemCategory, LibItemTemplate } from '../../../entities/types/Spaces'
import { uploadAvatarImage } from '../../../gateways/FileStoreGateway'
import { ItemFormValidator } from '../../../validators/itemFormValidator'
import { AvatarCircle } from '../../atoms'
import { ImageCropper } from '../../atoms/ImageCropper/ImageCropper'
import TextInput from '../../atoms/Input/TextInput'
import { customStyles } from '../../atoms/ReactSelectStyling/selectStyle'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOut/SlideoutFooter'

type ManageItemFormProps = {
  item: LibItemTemplate
  totalCategories: LibItemCategory[]
  isCreate: boolean
  isTheorem: boolean
  onSubmit: (item: LibItemTemplate) => void
  onClose: () => void
  onDiscard: () => void
}

export function ManageItemSlideOut(props: ManageItemFormProps) {
  const [canSave, setCanSave] = useState(true)
  const [imageUrl, setImageUrl] = useState<string | undefined>(props.item.image)
  const [currentCategory, setCurrentCategory] = useState<LibItemCategory | undefined>(props.item.category)

  const { control, formState: { errors, isDirty }, handleSubmit, register, setValue, trigger } = useForm<
    LibItemTemplate
  >({
    defaultValues: props.item,
    resolver: yupResolver(ItemFormValidator()),
  })

  const onDeleteImage = useCallback(() => {
    setImageUrl(undefined)
  }, [setImageUrl])

  const onSetImageUrl = useCallback(async (file: File) => {
    const url = await uploadAvatarImage(file)
    setImageUrl(url)
    setValue('image', url)
  }, [setImageUrl])

  const handleCategoryChange = useCallback((category: LibItemCategory) => {
    setCurrentCategory(category)
    setValue('category', category)
  }, [setCurrentCategory, setValue])

  const hasImage = imageUrl && imageUrl !== ''

  return (
    <SlideOut
      title={props.isCreate ? 'Create Item' : 'Manage Item'}
      onClose={() => isDirty ? props.onDiscard() : props.onClose()}
    >
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <div className='flex flex-row mb-6'>
          <AvatarCircle className='mr-6' url={imageUrl || ''} sizeRem={5} />
          <div className={`flex flex-col ${!hasImage ? 'justify-end' : ''}`}>
            <label htmlFor='cropper' className='text-cta-200 cursor-pointer font-normal'>
              <div className={`flex flex-row ${hasImage ? 'mt-3' : ''}`}>
                <div className='mr-3 text-teal-300'>
                  <UploadIcon />
                </div>
                <div className={`${hasImage ? 'mb-3' : ''} text-teal-300 font-normal`}>Upload Image</div>
              </div>
            </label>
            {hasImage
              && (
                <button onClick={onDeleteImage}>
                  <div className='flex flex-row'>
                    <div className='mr-3 text-teal-300'>
                      <TrashIcon />
                    </div>
                    <div className='mb-6 text-teal-300 font-normal'>Delete Image</div>
                  </div>
                </button>
              )}
            <ImageCropper
              size={300}
              setCroppedImageFile={onSetImageUrl}
            />
          </div>
        </div>
        <div className='flex flex-row'>
          <div className='w-full'>
            <div className='mb-6'>
              <TextInput label='Name' labelClass='text-gray-600' hasAutoFocus {...register('name')} />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>{errors.name?.message}</span>
            </div>
          </div>
        </div>
        <div className='mb-10 border-b border-primary-400'>
          <Select
            placeholder='Category'
            options={[{
              options: props.totalCategories.map(category => ({
                description: category.name,
                label: category.name,
                value: category,
              })),
            }]}
            styles={{
              ...customStyles,
              control: (base: any, state: { isFocused: any }) => ({
                ...base,
                border: state.isFocused ? 0 : 0,
                boxShadow: state.isFocused ? 0 : 0,
                height: 40,
                width: '100%',
              }),
            }}
            onChange={(val) => {
              if (val && val.value) {
                handleCategoryChange(val.value)
              }
            }}
            value={currentCategory
              ? {
                description: currentCategory.name,
                label: currentCategory.name,
                value: currentCategory,
              }
              : undefined}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                neutral10: 'none',
                primary: 'lightblue',
              },
            })}
          />
          <span className='flex flex-grow ml-2 text-error font-medium text-xs'>
            {errors.category?.id?.message}
          </span>
        </div>

        <div className='flex flex-row'>
          <div className='flex flex-row'>
            <div className='w-full'>
              <div className='mb-6'>
                <TextInput label='Low price' type='number' hasAutoFocus prefix='$' {...register('lowPrice')} />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.lowPrice?.message}
                </span>
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <div className='w-full ml-5'>
              <div className='mb-6'>
                <TextInput label='Mid price' type='number' prefix='$' hasAutoFocus {...register('midPrice')} />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.midPrice?.message}
                </span>
              </div>
            </div>
          </div>
          <div className='flex flex-row'>
            <div className='w-full ml-5'>
              <div className='mb-6'>
                <TextInput label='High price' type='number' prefix='$' hasAutoFocus {...register('highPrice')} />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.highPrice?.message}
                </span>
              </div>
            </div>
          </div>
        </div>

        <SlideOutFooter>
          <button
            className={`bg-cta-200 hover:bg-cta-300 text-white p-3 rounded-lg font-semibold mr-2 ${
              canSave ? '' : 'opacity-25'
            }`}
            type='submit'
            disabled={!canSave}
          >
            {props.isCreate ? 'Create Item' : 'Save'}
          </button>
        </SlideOutFooter>
      </form>
    </SlideOut>
  )
}
