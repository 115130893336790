import page from 'page'
import { Context } from './index'

export enum RouteNames {
  Dashboard = 'Home',
  PreDefinedContent = 'Pre-defined Content',
  Tenants = 'Tenants',
}

export function initializeRouter(context: Context): void {
  page.base('')

  page('/', () => {
    context.actions.displayDashboardAction()
  })

  page('/pre-defined-content', async () => {
    await context.actions.getSpaceTemplatesAction()
    await context.actions.getItemTemplatesAction()
    await context.actions.getAllTasklistTemplates()
    context.actions.displayPreDefinedContentAction()
  })

  page('/tenants/management', () => {
    context.actions.getTenantsManagementAction()
  })

  page('/users/edit', () => {
    context.actions.displayUserEditAction()
  })

  page('/users/management', () => {
    context.actions.displayUserManagementAction()
  })

  page('*', () => {
    context.actions.displayNotFoundAction()
  })

  page.start()
}

export function redirect(url: string): void {
  page.redirect(url)
}
