import React, { ReactNode } from 'react'
import CogIcon from 'theorem-lib/src/assets/icons/cog.svg'
import TrashIcon from 'theorem-lib/src/assets/icons/trash.svg'
import { useActions } from '../../presenter'

export interface SpaceListItemProps {
  name: string
  industry: string
  id: string
}

export const SpaceList = ({ spaces }: { spaces: SpaceListItemProps[] }) => {
  return (
    <div className='w-full'>
      <SpaceListHeaders />
      {spaces.map((space, index) => <SpaceListItem key={index} {...space} />)}
    </div>
  )
}

const SpaceListRowWrapper = ({ children }: { children: ReactNode }) => (
  <div className='grid grid-cols-7 items-center py-4 border-b border-primary-500 first:border-0 last:border-0'>
    {children}
  </div>
)

const SpaceListHeaders = () => {
  return (
    <SpaceListRowWrapper>
      <div className='col-span-3 uppercase font-semibold text-sm'>Name</div>
      <div className='col-span-3 uppercase font-semibold text-sm'>Industry</div>
    </SpaceListRowWrapper>
  )
}

const SpaceListItem = ({ id, industry, name }: SpaceListItemProps) => {
  const { deleteSpaceAction, displaySlideOutAction, fetchIndustries, fetchItemTemplates, fetchSpaceTemplateById } =
    useActions()

  const onPress = async () => {
    fetchItemTemplates()
    fetchIndustries()
    await fetchSpaceTemplateById(id)
    displaySlideOutAction('ManageSpace')
  }

  const onDelete = async () => {
    await deleteSpaceAction(id)
  }

  return (
    <SpaceListRowWrapper>
      <div className='col-span-3'>{name}</div>
      <div className='col-span-3'>{industry}</div>
      <div className='col-span-1 space-x-5 flex items-center justify-end'>
        <button className='flex items-center justify-center text-grey-900' title='Manage space' onClick={onPress}>
          <CogIcon />
        </button>
        <button className='flex items-center justify-center text-grey-900' title='Delete space' onClick={onDelete}>
          <TrashIcon />
        </button>
      </div>
    </SpaceListRowWrapper>
  )
}
