import { gql } from '@apollo/client'

export const ListSpaceTemplatesDocument = gql `
query {
    listSpaceTemplates {
      id,
      name,
      description,
      industry {
        id,
        name,
        covers {
          id,
          url,
        }
      }
      image,
      items {
        id,
        name,
        category {
            id,
          name,
          createdOn,
          modifiedOn,
        },
        lowPrice,
        midPrice,
        highPrice,
        createdOn,
        modifiedOn,
        image,
        quantity,
      },
      modifiedOn,
      createdOn
    },
  }
`

export const GetSpaceTemplateByIdDocument = gql `
query GetSpaceTemplateById($id: ID!) {
  getSpaceTemplateById(templateId: $id) {
    id,
    name,
    description,
    industry {
      id,
      name,
      covers {
        id,
        url,
      }
    }
    image,
    items {
      id,
      name,
      category {
          id,
        name,
        createdOn,
        modifiedOn,
      },
      lowPrice,
      midPrice,
      highPrice,
      createdOn,
      modifiedOn,
      image,
      quantity,
    },
    modifiedOn,
    createdOn
  },
}`

export const UpdateSpaceTemplateDocument = gql `
mutation UpdateSpaceTemplate($input: UpdateSpaceTemplateInput!) {
  updateSpaceTemplate(input: $input)
}
`

export const CreateSpaceTemplateDocument = gql `
mutation CreateSpaceTemplate($input: CreateSpaceTemplateInput!) {
  createSpaceTemplate(input: $input)
}
`

export const FetchIndustriesDocument = gql `
query {
  getIndustries {
    id,
    name,
    covers {
      id,
      url
    }
  }
}
`

const SpaceTemplateFragment = gql `
  fragment SpaceTemplate on SpaceTemplate {
    id
    name
    description
    industry {
      id
      name
      covers {
        id
        url
      }
    }
    image
    items {
      id
      name
      category {
        id
        name
        createdOn
        modifiedOn
      }
      lowPrice
      midPrice
      highPrice
      createdOn
      modifiedOn
      image
      quantity
    }
    modifiedOn
    createdOn
  }
`

export const DeleteSpaceTemplateDocument = gql `
  mutation DeleteSpaceTemplate($id: ID!) {
    deleteSpaceTemplate(id: $id)
  }
`
