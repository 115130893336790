export enum SidebarIcons {
  Home = 'Home',
  Case = 'Case',
  Cog = 'Cog',
  Claim = 'Claim',
  Help = 'Help',
  Tenants = 'Tenants',
  MRLIndeal = 'MRLIndeal',
  Slack = 'Slack',
}
