import React, { useCallback } from 'react'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import { useActions } from '../../../../presenter'
import { useAppState } from '../../../../presenter'
import { ItemList } from '../../../molecules/ItemList/ItemList'
import NoInfo from '../NoInfo/NoInfo'

export function Items() {
  const { displaySlideOutAction, fetchItemTemplateCategories } = useActions()
  const { itemTemplates } = useAppState()

  const onAddNew = useCallback(async () => {
    await fetchItemTemplateCategories()
    displaySlideOutAction('CreateItem')
  }, [])

  return (
    <>
      <div className='flex flex-row flex-shrink justify-end'>
        <button
          title='Add Item'
          className='w-10 h-10 rounded-md flex justify-center items-center bg-teal-300 hover:bg-teal-400'
        >
          <PlusIcon className='text-white' onClick={onAddNew} />
        </button>
      </div>

      <div className='flex grow'>
        {itemTemplates.length > 0
          ? <ItemList items={itemTemplates} />
          : <NoInfo texts={['items', 'Item']} onAddNew={onAddNew} />}
      </div>
    </>
  )
}
