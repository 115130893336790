import axios, { AxiosError } from 'axios'
import config from '../../../../app/client/src/config'
import {
  ForbiddenError,
  InternalServerError,
  UnauthenticatedError,
  UnexpectedError,
} from '../../../../app/client/src/presenter/errors'

export const uploadAvatarImage = async (file: File): Promise<string> => {
  const bodyFormData = new FormData()
  bodyFormData.append('avatar', file)

  try {
    const results = await axios({
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: `${config.fileStoreUrl}/api/v1/avatar`,
      withCredentials: true,
    })

    return `${config.fileStoreUrl}/api/v1/avatar/${results.data.uuid}`
  } catch (err: unknown | AxiosError) {
    if (axios.isAxiosError(err)) {
      if (err.response?.status == 401) throw new UnauthenticatedError()
      if (err.response?.status == 403) throw new ForbiddenError()
      if (err.response?.status == 404) throw new UnexpectedError()
      if (err.response?.status == 500) throw new InternalServerError()
    }
    throw new UnexpectedError()
  }
}
