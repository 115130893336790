import { PlusIcon, RefreshIcon } from '@heroicons/react/solid'
import React from 'react'
import AvatarCircle from 'theorem-lib/src/components/atoms/AvatarCircle/AvatarCircle'
import AvatarInitial from 'theorem-lib/src/components/atoms/AvatarInitial/AvatarInitial'
import { Toggle } from 'theorem-lib/src/components/atoms/Toggle/Toggle'
import { SecurityRole, Status, User } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'

type AppStateType = {
  filteredUsers: User[]
}

export function UserManagement() {
  const { filteredUsers } = useAppState<AppStateType>()
  const { authenticatedUser } = useAppState()
  const {
    displaySlideOutAction,
    reinviteUserAction,
    searchUser,
    toggleAdminAction,
    updateUserStatusAction,
  } = useActions()

  return (
    <>
      <h2 className='text-xl font-medium mb-5'>User Management</h2>
      <div className='mb-4 flex flex-col'>
        <label htmlFor='text'>Search users</label>
        <input id='text' type='text' className='md:max-w-xs' onChange={e => searchUser(e.target.value)} />
        <button
          type='button'
          className='button cta my-8 invite absolute right-8 top-16'
          onClick={() => displaySlideOutAction('AddUserSlideOut')}
        >
          <PlusIcon className='h-5 w-5' />
        </button>
      </div>
      <table className='w-full'>
        <thead>
          <tr>
            <th className='p-4'>User</th>
            <th className='p-4'>Email</th>
            <th className='p-4'>Status</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map(user => (
            <tr key={user.id} className=' even:bg-gray-100'>
              <td className='p-4'>
                <div className='inline-block mr-3'>
                  {user.avatarUrl
                    ? <AvatarCircle url={user.avatarUrl} sizeRem={2} />
                    : (
                      <AvatarInitial
                        firstName={user.firstName || ''}
                        lastName={user.lastName || ''}
                        sizeRem={2}
                      />
                    )}
                </div>
                <span className='inline-block'>{user.firstName} {user.lastName}</span>
              </td>
              <td className='p-4'>
                <span className='inline-block'>{user.email}</span>
              </td>
              <td className='p-4'>
                {(user.status != Status.Pending && user.id != authenticatedUser.id) && (
                  <Toggle
                    enabled={user.status == Status.Active}
                    setEnabled={() => {
                      if (user.status == Status.Active) updateUserStatusAction({ status: Status.Inactive, user })
                      else updateUserStatusAction({ status: Status.Active, user })
                    }}
                  />
                )}

                {user.status === Status.Inactive && <span className='ml-4'>Deactivated</span>}

                {user.status === Status.Pending && (
                  <>
                    <button
                      type='button'
                      className='button'
                      onClick={() => {
                        reinviteUserAction({ user })
                      }}
                    >
                      <RefreshIcon className='h-5 w-5' />
                    </button>
                    <span className='ml-4'>Pending</span>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
