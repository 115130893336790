import React from 'react'
import { ToastContainer } from 'react-toastify'
import { Forbidden } from 'theorem-lib/src/components/errors/Forbidden'
import { NotFound } from 'theorem-lib/src/components/errors/NotFound'
import { Unexpected } from 'theorem-lib/src/components/errors/Unexpected'
import { Sidebar } from 'theorem-lib/src/components/molecules/Sidebar/Sidebar'
import { useAppState } from '../presenter'

import { Dashboard } from './templates/Dashboard'
import { Loading } from './templates/Loading'
import { UserEdit } from './templates/Users/UserEdit'
import { UserManagement } from './templates/Users/UserManagement'

import 'react-toastify/dist/ReactToastify.css'
import TheoremLogo from 'theorem-lib/src/assets/logos/theorem.svg'
import { GenericCancelModal } from './organisms/Modals/GenericCancelModal'
import { GenericDeleteModal } from './organisms/Modals/GenericDeleteModal'
import { AddUserSlideOut } from './organisms/SlideOuts/AddUserSlideOut'
import { CreateItemSlideOut } from './organisms/SlideOuts/CreateItemSlideOut'
import { CreateListSlideOut } from './organisms/SlideOuts/CreateListSlideOut'
import { CreateSpaceSlideOut } from './organisms/SlideOuts/CreateSpaceSlideOut'
import { ManageItemSlideOut } from './organisms/SlideOuts/ManageItemSlideOut'
import { ManageSpaceSlideOut } from './organisms/SlideOuts/ManageSpaceSlideOut'
import { ManageTenantInfoSlideOut } from './organisms/SlideOuts/ManageTenantInfoSlideOut'
import PreDefinedContent from './templates/PreDefinedContent/PreDefinedContent'
import { ManageTasklist } from './templates/PreDefinedContent/Workbook/ManageWorkbook/manageTasklist'
import Tenants from './templates/Tenants/Tenants'

const pages: { [key: string]: () => JSX.Element } = {
  Dashboard,
  Forbidden,
  Loading,
  ManageTasklist,
  NotFound,
  PreDefinedContent,
  Tenants,
  Unexpected,
  UserEdit,
  UserManagement,
}

const modals: { [key: string]: () => JSX.Element } = {
  GenericCancelModal: () => <GenericCancelModal />,
  GenericDeleteModal: () => <GenericDeleteModal />,
  None: () => <></>,
}

const slideOuts: { [key: string]: () => JSX.Element } = {
  AddUserSlideOut: () => <AddUserSlideOut />,
  CreateItem: () => <CreateItemSlideOut />,
  CreateList: () => <CreateListSlideOut />,
  CreateSpace: () => <CreateSpaceSlideOut />,
  ManageItem: () => <ManageItemSlideOut />,
  ManageSpace: () => <ManageSpaceSlideOut />,
  ManageTenantLic: () => <ManageTenantInfoSlideOut />,
  None: () => <></>,
}

export function CurrentPage() {
  const { authenticatedUser, currentModal, currentPage, currentSlideOut, lockCurrentPageChange, navOptions } =
    useAppState()
  const CurrentPage = pages[currentPage]
  const CurrentSlideOut = slideOuts[currentSlideOut]
  const CurrentModal = modals[currentModal]

  return (
    currentPage !== 'Loading' && !lockCurrentPageChange
      ? (
        <>
          <Sidebar
            navOptions={navOptions}
            name={{ first: authenticatedUser.firstName || '', last: authenticatedUser.lastName || '' }}
            avatar={authenticatedUser.avatarUrl || ''}
            PlatformLogo={<TheoremLogo />}
          >
            <CurrentPage />
            <CurrentModal />
            <CurrentSlideOut />
            <ToastContainer position='top-center' hideProgressBar={true} />
          </Sidebar>
        </>
      )
      : <CurrentPage />
  )
}
