import React, { useCallback, useState } from 'react'
import TabNav, { TabLink } from 'theorem-lib/src/components/atoms/TabNav/TabNav'
import { Items } from './Items/Items'
import { Spaces } from './Spaces/Spaces'
import { Workbooks } from './Workbook/Workbook'

enum TabsAccessors {
  items = 'items',
  spaces = 'spaces',
  workbooks = 'workbooks',
}

const TabTitles: Record<TabsAccessors, string> = {
  items: 'Items',
  spaces: 'Spaces',
  workbooks: 'Workbook',
}

const tabs: TabLink[] = [
  {
    Content: <Spaces />,
    accessor: TabsAccessors.spaces,
    label: TabTitles.spaces,
  },
  {
    Content: <Items />,
    accessor: TabsAccessors.items,
    label: TabTitles.items,
  },
  {
    Content: <Workbooks />,
    accessor: TabsAccessors.workbooks,
    label: TabTitles.workbooks,
  },
]

const PreDefinedContent = () => {
  const [selectedTab, setSelectedTab] = useState<TabLink>(() => {
    const queryParams = new URL(window.location.href)
    const selectedTab = queryParams.searchParams.get('selectedTab')
    if (selectedTab !== undefined) {
      for (const tab of tabs) {
        if (tab.accessor === selectedTab) {
          return tab
        }
      }
    }
    return tabs[0]
  })

  const handleTabSelection = useCallback(
    (selectedTab: TabLink) => {
      setSelectedTab(selectedTab)
    },
    [setSelectedTab],
  )

  return (
    <div className='flex flex-col min-h-full'>
      <div className='flex flex-row flex-shrink justify-between items-end mb-5'>
        <h2 className='text-3xl font-normal'>{selectedTab.label}</h2>
        <TabNav
          defaultTab={selectedTab.accessor}
          tabs={tabs}
          onTabSelection={handleTabSelection}
          color='teal'
        />
      </div>

      <div className='grow flex flex-col'>{selectedTab.Content}</div>
    </div>
  )
}

export default PreDefinedContent
