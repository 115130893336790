import React, { useMemo } from 'react'
import { ManageSpaceSlideOut as LibSlideOut } from 'theorem-lib/src/components/organisms/ManageSpaces/ManageSpaceSlideOut'
import { LibIndustry, LibItemTemplate, LibSpaceTemplate } from 'theorem-lib/src/entities/types/Spaces'
import {
  SpaceItemTemplate,
  SpaceTemplate,
  SpaceTemplateItemInput,
  UpdateSpaceTemplateInput,
} from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'

const convertGqlSpaceTemplateToLibTemplate = (template: SpaceTemplate): LibSpaceTemplate => {
  return {
    ...template,
    description: template.description || '',
    image: template.image || '',
    industry: template.industry
      ? {
        ...template.industry,
        covers: template.industry.covers || undefined,
      }
      : undefined,
    items: template.items?.map((item) => {
      return {
        ...item,
        image: item.image || '',
        quantity: item.quantity || 0,
      }
    }) || [],
    quantity: 0,
  }
}

const convertGqlItemTemplatesToLibTemplate = (items: SpaceItemTemplate[]): LibItemTemplate[] => {
  return items.map((item) => {
    return {
      ...item,
      image: item.image ?? '',
      quantity: item.quantity ?? 0,
    }
  })
}

export const ManageSpaceSlideOut = () => {
  const { displayModalAction, displaySlideOutAction, resetCurrentSpaceTemplate, updateSpaceTemplateAction } =
    useActions()
  const { industryList, itemTemplates, selectedSpaceTemplate } = useAppState()

  const onClose = () => {
    displaySlideOutAction('None')
    resetCurrentSpaceTemplate()
  }

  const onDiscard = () => {
    displayModalAction('GenericCancelModal')
  }

  const libIndustries: LibIndustry[] = useMemo(() => {
    return industryList.map((industry) => {
      return {
        ...industry,
        covers: industry.covers ? industry.covers : undefined,
      }
    })
  }, [industryList])

  const onSubmit = async (updatedSpace: LibSpaceTemplate & { newItems: LibItemTemplate[] }) => {
    const allItems = [
      ...updatedSpace.items,
      ...updatedSpace.newItems,
    ]

    const convertedItems: SpaceTemplateItemInput[] = allItems.filter((item) =>
      item.id !== undefined && item.id !== null
    ).map((item) => {
      return {
        id: item.id!,
        quantity: item.quantity,
      }
    })

    const updatedInput: UpdateSpaceTemplateInput = {
      description: updatedSpace.description,
      id: updatedSpace.id,
      image: updatedSpace.image,
      industry: updatedSpace.industry?.id,
      items: convertedItems,
      name: updatedSpace.name,
    }

    await updateSpaceTemplateAction(updatedInput)
    resetCurrentSpaceTemplate()
    displaySlideOutAction('None')
  }

  if (selectedSpaceTemplate === undefined) {
    return <></>
  }

  const spaceTemplate: LibSpaceTemplate = convertGqlSpaceTemplateToLibTemplate(selectedSpaceTemplate)
  const totalItems: LibItemTemplate[] = convertGqlItemTemplatesToLibTemplate(itemTemplates)

  return (
    <LibSlideOut
      space={spaceTemplate}
      totalItems={totalItems}
      onClose={onClose}
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      industries={libIndustries}
      isTemplate={true}
      isCreate={false}
      isTheorem={true}
    />
  )
}
