import React from 'react'
import { useForm } from 'react-hook-form'
import TextInput from 'theorem-lib/src/components/atoms/Input/TextInput'
import { SlideOut as LibSlideOut } from 'theorem-lib/src/components/atoms/SlideOut/SlideOut'
import { SlideOutFooter } from 'theorem-lib/src/components/atoms/SlideOut/SlideoutFooter'
import { LibItemTemplate } from 'theorem-lib/src/entities/types/Spaces'
import { CreateItemTemplateInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'

type NewList = {
  description: string | undefined
  name: string | undefined
}

export const CreateListSlideOut = () => {
  const {
    createTasklistTemplate,
    displayModalAction,
    displaySlideOutAction,
    getAllTasklistTemplates,
    manageTasklistTemplateById,
  } = useActions()

  const { control, formState: { errors, isDirty }, handleSubmit, register, setValue, trigger } = useForm<NewList>()

  const onClose = () => {
    if (isDirty) {
      displayModalAction('GenericCancelModal')
    } else {
      displaySlideOutAction('None')
    }
  }

  const onSubmit = async (newList: NewList) => {
    const listId = await createTasklistTemplate({
      description: newList.description || '',
      isDefault: true,
      isVisible: true,
      name: newList.name || '',
    })
    displaySlideOutAction('None')
    await getAllTasklistTemplates()
    manageTasklistTemplateById(listId)
  }

  return (
    <LibSlideOut title='Create List' onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col'>
          <div className='w-full'>
            <div className='mb-6'>
              <TextInput label='Name' hasAutoFocus {...register('name', { required: true })} />
              <span className='flex flex-grow text-error font-medium text-xs'>
                {errors.name ? 'This field is required' : ''}
              </span>
            </div>
          </div>

          <div className='w-full'>
            <div className='mb-6'>
              <TextInput label='Description' {...register('description', { required: true })} />
              <span className='flex flex-grow text-error font-medium text-xs'>
                {errors.description ? 'This field is required' : ''}
              </span>
            </div>
          </div>
        </div>

        <SlideOutFooter>
          <button
            className='bg-cta-200 hover:bg-cta-300 text-white p-3 rounded-lg font-semibold mr-2'
            type='submit'
          >
            Create
          </button>
        </SlideOutFooter>
      </form>
    </LibSlideOut>
  )
}
