import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import './ImageCropper.css'

type ImageCropperProps = {
  size: number
  setCroppedImageFile: (file: File) => void
}

export function ImageCropper(props: ImageCropperProps): JSX.Element {
  const [completedCrop, setCompletedCrop] = useState<Crop>()
  const [crop, setCrop] = useState<Crop>({ aspect: 1, height: 40, unit: 'px', width: 40, x: 0, y: 0 })
  const [upImg, setUpImg] = useState<string>()
  const imgRef = useRef(null)
  const fileRef = useRef<HTMLInputElement>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () => setUpImg(reader.result as string))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image: HTMLImageElement = imgRef.current
    const canvas: HTMLCanvasElement = previewCanvasRef.current
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    const pixelRatio = props.size / (completedCrop.width * scaleX)

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    )
  }, [completedCrop])

  function generateDownload(canvas: HTMLCanvasElement, finishedCrop: Crop): void {
    if (!finishedCrop || !canvas) {
      return
    }

    canvas.toBlob(
      (blob): void => {
        const file = new File([blob as Blob], 'avatar.png', { type: 'image/png' })
        props.setCroppedImageFile(file)
      },
      'image/png',
      1,
    )
  }

  return (
    <div className='m-0'>
      <input
        className='hidden'
        type='file'
        accept='image/x-png,image/png,image/jpeg,image/jpg,image/gif'
        id='cropper'
        ref={fileRef}
        onChange={onSelectFile}
      />
      {upImg !== undefined && (
        <ReactCrop
          src={upImg as string}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c): void => setCrop(c)}
          onComplete={(c): void => setCompletedCrop(c)}
        />
      )}

      <div className='hidden'>
        <canvas
          ref={previewCanvasRef}
          height={props.size}
          width={props.size}
        />
      </div>
      {(completedCrop?.width && completedCrop?.height && previewCanvasRef.current) && (
        <div>
          <div>Please crop your image before saving it</div>
          <button
            type='button'
            className='button mb-8'
            disabled={!completedCrop?.width || !completedCrop?.height || !previewCanvasRef.current}
            onClick={(): void => {
              setUpImg(undefined)
              setCompletedCrop(undefined)
              generateDownload(previewCanvasRef.current as HTMLCanvasElement, completedCrop as Crop)
              if (fileRef.current) fileRef.current.value = ''
            }}
          >
            Save Image
          </button>
        </div>
      )}
    </div>
  )
}
