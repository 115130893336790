import React from 'react'
import CogIcon from 'theorem-lib/src/assets/icons/cog.svg'
import { Tenant } from '../../../generated/api'
import { useActions } from '../../../presenter'

export const TenantTable = ({ tenants }: { tenants: Tenant[] }) => {
  return (
    <table className='w-full'>
      <TenantListHeaders />
      {<TenantBody tenants={tenants} />}
    </table>
  )
}

const TenantListHeaders = () => {
  return (
    <thead>
      <tr>
        <th className='p-4 text-gray-500 uppercase font-semibold text-sm'>Tenant</th>
        <th className='p-4 text-gray-500 uppercase font-semibold text-sm'>Subdomain</th>
        <th className='p-4 text-gray-500 uppercase font-semibold text-sm'>Licenses Used/Remaining</th>
      </tr>
    </thead>
  )
}

const TenantBody = ({ tenants }: { tenants: Tenant[] }) => {
  const { displaySlideOutAction, setSelectedTenant } = useActions()

  const onManage = async (tenant: Tenant) => {
    await setSelectedTenant(tenant)
    displaySlideOutAction('ManageTenantLic')
  }

  const getusedRemaining = (used: number, remaining: number) => {
    return `${used} / ${remaining}`
  }

  return (
    <tbody>
      {tenants?.map(tenant => (
        (
          <tr key={tenant.ownerId} className='even:bg-gray-100'>
            <td className='p-4'>
              <span className='inline-block'>{tenant.name}</span>
            </td>
            <td className='p-4'>
              <span className='inline-block'>{tenant.subDomain}</span>
            </td>
            <td className='p-4'>
              <div
                className={`h-7 w-16 rounded text-center
              ${
                  tenant.details
                    ? ((tenant.details.used ?? 0) / tenant.details.licenses === 1
                      ? 'bg-yellow-100'
                      : ((tenant.details.used ?? 0) > tenant.details.licenses)
                      ? 'bg-red-100'
                      : '')
                    : ('')
                }
              `}
              >
                <span className='inline-block font-bold'>
                  {tenant.details
                    ? getusedRemaining(tenant.details.used ?? 0, tenant.details.licenses)
                    : ''}
                </span>
              </div>
            </td>
            <td>
              <div className='col-span-1 space-x-5 flex items-center justify-end'>
                {tenant.details
                  ? (
                    <button
                      className='flex items-center justify-center text-grey-900 htenant'
                      title='Manage Tenant'
                      onClick={() => onManage(tenant)}
                    >
                      <CogIcon />
                    </button>
                  )
                  : <></>}
              </div>
            </td>
          </tr>
        )
      ))}
    </tbody>
  )
}
