import { ApolloClient, ApolloError, InMemoryCache, ServerError } from '@apollo/client'
import { cloneDeep, sortBy } from 'lodash'
import config from '../config'
import {
  CreateItemTemplateInput,
  CreateSpaceTemplateInput,
  ItemCategory,
  SpaceItemTemplate,
  SpaceTemplate,
  UpdateItemTemplateInput,
  UpdateSpaceTemplateInput,
} from '../generated/api'
import { UnauthenticatedError, UnexpectedError } from '../presenter/errors'
import {
  createItemTemplateDocument,
  DeleteSpaceItemTemplateDocument,
  getItemCategoriesDocument,
  getSpaceItemTemplateByIdDocument,
  getSpaceItemTemplatesDocument,
  UpdateSpaceItemTemplateDocument,
} from './documents/items.document'
import {
  CreateSpaceTemplateDocument,
  DeleteSpaceTemplateDocument,
  FetchIndustriesDocument,
  GetSpaceTemplateByIdDocument,
  ListSpaceTemplatesDocument,
  UpdateSpaceTemplateDocument,
} from './documents/spaces.document'

const client = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'include',
  uri: config.theoremGraphqlUrl,
})

function isApolloError(err: unknown | ApolloError): err is ApolloError {
  return (err as ApolloError).graphQLErrors !== undefined
}

function isServerError(err: unknown | ServerError): err is ServerError {
  return (err as ServerError).name == 'ServerError'
}

function graphqlErrorHandler(err: unknown | ApolloError) {
  if (isApolloError(err)) {
    const networkError = err.networkError
    if (networkError && isServerError(networkError)) {
      if (networkError.statusCode == 401) throw new UnauthenticatedError()
      if (networkError.statusCode == 404) throw new UnexpectedError()
    } else {
      const graphQlErrors = (err as ApolloError).graphQLErrors
      if (graphQlErrors.length > 0) {
        if (graphQlErrors[0].message.startsWith('A space template already exists')) {
          throw new Error('A space already exists with that name')
        } else if (graphQlErrors[0].message.startsWith('An item template already exists')) {
          throw new Error('An item already exists with that name')
        }
      }
    }
  }
}

export const fetchSpaceById = async (templateId: string): Promise<SpaceTemplate> => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'no-cache',
      query: GetSpaceTemplateByIdDocument,
      variables: {
        id: templateId,
      },
    })
    return cloneDeep(data.getSpaceTemplateById)
  } catch (err: unknown | Error | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateSpaceTemplate = async (input: UpdateSpaceTemplateInput) => {
  try {
    await client.mutate({
      mutation: UpdateSpaceTemplateDocument,
      variables: {
        input,
      },
    })
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const fetchIndustries = async () => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'no-cache',
      query: FetchIndustriesDocument,
    })

    return cloneDeep(data.getIndustries)
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const createSpaceTemplate = async (input: CreateSpaceTemplateInput): Promise<string> => {
  try {
    const { data } = await client.mutate({
      mutation: CreateSpaceTemplateDocument,
      variables: {
        input,
      },
    })

    return data.createSpaceTemplate
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const fetchItemTemplateCategories = async (): Promise<ItemCategory[]> => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'no-cache',
      query: getItemCategoriesDocument,
    })

    return cloneDeep(data.getItemTemplateCategories)
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const fetchItemTemplateById = async (templateId: string): Promise<SpaceItemTemplate> => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'no-cache',
      query: getSpaceItemTemplateByIdDocument,
      variables: {
        id: templateId,
      },
    })

    return cloneDeep(data.getSpaceItemTemplateById)
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getSpaceTemplates = async (): Promise<SpaceTemplate[]> => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'no-cache',
      query: ListSpaceTemplatesDocument,
    })
    return sortBy(data.listSpaceTemplates, ['name'])
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const createItemTemplate = async (input: CreateItemTemplateInput): Promise<string> => {
  try {
    const { data } = await client.mutate({
      mutation: createItemTemplateDocument,
      variables: {
        input,
      },
    })

    return data.CreateItemTemplate
  } catch (err) {
    const check = err as ApolloError
    console.log(check.graphQLErrors)
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteSpaceTemplate = async (id: string): Promise<void> => {
  try {
    await client.mutate({
      mutation: DeleteSpaceTemplateDocument,
      variables: { id },
    })
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getItemTemplates = async (): Promise<SpaceItemTemplate[]> => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'no-cache',
      query: getSpaceItemTemplatesDocument,
    })
    return sortBy(data.getSpaceItemTemplates, ['name'])
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteItemTemplate = async (id: string): Promise<void> => {
  try {
    await client.mutate({
      mutation: DeleteSpaceItemTemplateDocument,
      variables: { id },
    })
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateItemTemplate = async (input: UpdateItemTemplateInput): Promise<void> => {
  try {
    await client.mutate({
      mutation: UpdateSpaceItemTemplateDocument,
      variables: {
        input,
      },
    })
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
