import React from 'react'

type AvatarCircleProps = {
  url: string
  className?: string
  sizeRem?: number
}

export const AvatarCircle = ({ className, sizeRem, url }: AvatarCircleProps): React.ReactElement => {
  const avatarSize = sizeRem ? sizeRem : 2.25
  if (url && url !== '') {
    return (
      <img
        className={`inline-block rounded-full flex-shrink-0 ${className}`}
        style={{ height: avatarSize + 'rem', width: avatarSize + 'rem' }}
        src={url}
        alt='avatar'
      />
    )
  } else {
    return (
      <div
        className={`inline-block rounded-full bg-grey-300 flex-shrink-0 ${className}`}
        style={{ height: avatarSize + 'rem', width: avatarSize + 'rem' }}
      />
    )
  }
}

export default AvatarCircle
