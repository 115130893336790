import Fuse from 'fuse.js'
import { Maybe, Scalars, User } from '../../../../app/client/src/generated/api'

interface HasFirstName {
  firstName?: string | Maybe<Scalars['String']>
}

export const byFirstName = (a: HasFirstName, b: HasFirstName) =>
  (a.firstName?.toLowerCase() ?? '').localeCompare(b.firstName?.toLowerCase() ?? '')

const getSortedUsers = (users: User[]): User[] => {
  const sortedUsers = [...users]
  sortedUsers.sort(byFirstName)
  return [...sortedUsers]
}

export const searchUsers = (searchString: string, users: User[]): User[] => {
  let filteredUsers: User[] = []
  if (searchString.trim().length === 0) {
    filteredUsers = users
  } else {
    const options: Fuse.IFuseOptions<User> = {
      ignoreLocation: true,
      keys: ['email', 'firstName', 'lastName'],
      threshold: 0.0,
    }
    const fuse = new Fuse(users, options)
    const results = fuse.search(searchString).map(result => result.item)
    filteredUsers = results
  }
  return getSortedUsers(filteredUsers)
}
