import { ApolloError } from '@apollo/client'
import { omit } from 'lodash'
import config from '../config'
import {
  AddRoleDocument,
  AdminUpdateUserDocument,
  GetTheoremUsersDocument,
  InviteTheoremAdminDocument,
  LoadCurrentUserDocument,
  RemoveRoleDocument,
  ResendTheoremAdminInviteLinkDocument,
  UpdateUserDocument,
  User,
} from '../generated/api'
import { UnexpectedError } from '../presenter/errors'
import { createApolloClient, graphqlErrorHandler } from './utils'

const client = createApolloClient(config.identityGraphqlUrl)

export const getAuthenticatedUser = async (): Promise<User> => {
  try {
    const { data } = await client.query({ fetchPolicy: 'no-cache', query: LoadCurrentUserDocument })
    return data.currentUser
  } catch (err: unknown | Error | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateAuthenticatedUser = async (user: User): Promise<void> => {
  try {
    await client.mutate({
      mutation: UpdateUserDocument,
      variables: {
        userInfo: {
          ...omit(user, ['id', 'status', 'isTheoremAdmin', 'securityRoles']),
          __typename: undefined,
        },
      },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getTheoremUsers = async (): Promise<User[]> => {
  try {
    const { data } = await client.query({ fetchPolicy: 'no-cache', query: GetTheoremUsersDocument })
    return data.getTheoremUsers
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

// TODO: why is this here and not called an there's an updateAuthenticatedUser?
export const updateUser = async (user: User): Promise<void> => {
  try {
    await client.mutate({
      mutation: UpdateUserDocument,
      variables: { ...omit(user, ['id', 'isTheoremAdmin']) },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const adminUpdateUser = async (user: User): Promise<void> => {
  try {
    await client.mutate({
      mutation: AdminUpdateUserDocument,
      variables: {
        id: user.id,
        userInfo: {
          ...omit(user, ['id', 'isTheoremAdmin', 'securityRoles']),
          __typename: undefined,
        },
      },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const inviteUser = async (email: string, firstName: string): Promise<void> => {
  try {
    await client.mutate({ mutation: InviteTheoremAdminDocument, variables: { email, firstName } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const reInviteUser = async (user: User): Promise<void> => {
  try {
    await client.mutate({
      mutation: ResendTheoremAdminInviteLinkDocument,
      variables: { email: user.email, id: user.id },
    })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const addRoleToUser = async (user: User, role: string): Promise<void> => {
  try {
    await client.mutate({ mutation: AddRoleDocument, variables: { id: user.id, role } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const removeRoleFromUser = async (user: User, role: string): Promise<void> => {
  try {
    await client.mutate({ mutation: RemoveRoleDocument, variables: { id: user.id, role } })
  } catch (err: unknown | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
