import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import TextInput from 'theorem-lib/src/components/atoms/Input/TextInput'
import { SlideOut as LibSlideOut } from 'theorem-lib/src/components/atoms/SlideOut/SlideOut'
import { SlideOutFooter } from 'theorem-lib/src/components/atoms/SlideOut/SlideoutFooter'
import { UpdateTenantDetailsInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'
import { LoadingSpinner } from '../../atoms/Loading/LoadingSpinner'

export const ManageTenantInfoSlideOut = () => {
  const { selectedTenant } = useAppState()
  const {
    displayModalAction,
    displaySlideOutAction,
    updateTenantInformation,
  } = useActions()
  const [loading, setLoading] = useState<boolean>(false)

  const onClose = () => {
    isDirty
      ? displayModalAction('GenericCancelModal')
      : displaySlideOutAction('None')
  }

  const onSubmit = async (tenantInfo: UpdateTenantDetailsInput) => {
    setLoading(true)
    await updateTenantInformation(tenantInfo)
    setLoading(false)
    displaySlideOutAction('None')
  }

  const { control, formState: { errors, isDirty }, handleSubmit, register, setValue, trigger } = useForm<
    UpdateTenantDetailsInput
  >({
    defaultValues: {
      email: selectedTenant?.details?.email ?? '',
      id: selectedTenant?.details?.id,
      licenses: selectedTenant?.details?.licenses,
      tenantName: selectedTenant?.details?.tenantName ?? '',
    },
  })

  return (
    <LibSlideOut
      title={!loading ? 'Manage Tenant' : ''}
      subtitle={!loading ? 'Configure license management for tenants.' : ''}
      maxWidth='max-w-2xl'
      onClose={onClose}
    >
      {!loading
        ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col'>
              <div className='w-full'>
                <div className='mb-16'>
                  <div className='text-base font-bold text-primary-200 mb-6'>Company Details</div>
                  <div className='flex flex-wrap'>
                    <div className='w-1/2 pr-2'>
                      <TextInput
                        label='Tenant Display Name'
                        {...register('tenantName', { required: true })}
                      />
                      <span className='flex flex-grow text-error font-medium text-xs'>
                        {errors.tenantName ? 'This field is required' : ''}
                      </span>
                    </div>
                    <div className='w-1/2 pl-2'>
                      <TextInput
                        label='Email'
                        {...register('email', { required: true })}
                      />
                      <span className='flex flex-grow text-error font-medium text-xs'>
                        {errors.email ? 'This field is required' : ''}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='mb-6 w-1/2'>
                  <div className='text-base font-bold text-primary-200 mb-6'>License Management</div>
                  <TextInput
                    label='Number of Licenses'
                    type='number'
                    {...register('licenses', { required: true, valueAsNumber: true })}
                  />
                  <span className='flex flex-grow text-error font-medium text-xs'>
                    {errors.licenses ? 'This field is required' : ''}
                  </span>
                </div>
              </div>
            </div>
            <SlideOutFooter>
              <button
                className='bg-cta-200 hover:bg-cta-300 text-white p-3 rounded-lg font-semibold mr-2'
                type='submit'
              >
                Save
              </button>
            </SlideOutFooter>
          </form>
        )
        : (
          <>
            <LoadingSpinner
              title='Updating Tenant'
              subtitle='Don&lsquo;t navigate away from this screen.'
            />
          </>
        )}
    </LibSlideOut>
  )
}
