import { sortBy } from 'lodash'
import React from 'react'
import IconManage from 'theorem-lib/src/assets/icons/cog.svg'
import IconTrash from 'theorem-lib/src/assets/icons/trash.svg'
import { Toggle } from 'theorem-lib/src/components/atoms/Toggle/Toggle'
import { TasklistTemplate } from '../../../../../generated/api'
import { useActions } from '../../../../../presenter'

export type TaskListTableProps = {
  taskLists: TasklistTemplate[]
}

const getNumTasks = (tasklist: TasklistTemplate): number => {
  let total = 0

  tasklist.sections?.forEach((section) => {
    section.tasks?.forEach((task) => {
      total++
    })
  })

  return total
}

export const TaskListTable = (props: TaskListTableProps) => {
  const {
    deleteTasklistTemplate,
    displayDeleteModalAction,
    getAllTasklistTemplates,
    manageTasklistTemplate,
    setTasklistTemplateDefault,
  } = useActions()
  const { taskLists: propTasklists } = props
  const taskLists = sortBy(propTasklists, (list) => list.name)

  const onDeleteTemplate = (taskList: TasklistTemplate) => {
    return () => {
      displayDeleteModalAction({
        mainText: 'Are you sure you want to delete',
        onDelete: async () => {
          await deleteTasklistTemplate(taskList.id)
          await getAllTasklistTemplates()
        },
        subText: `The list "${taskList.name}" and all of its tasks will go away forever`,
      })
    }
  }

  const onSetIsDefault = (tasklist: TasklistTemplate) => {
    return async () => {
      const isDefault = tasklist.isDefault || false
      const newDefault = !isDefault

      await setTasklistTemplateDefault({ isDefault: newDefault, templateId: tasklist.id })
    }
  }

  const onManageTasklist = (tasklist: TasklistTemplate) => {
    return () => {
      manageTasklistTemplate(tasklist)
    }
  }

  return (
    <>
      <table className='w-full height-max-content'>
        <thead>
          <tr>
            <th className='px-5 py-2'>Name</th>
            <th className='w-20'>Tasks</th>
            <th className='w-20'>Project Default</th>
            <th className='w-20'></th>
          </tr>
        </thead>

        <tbody>
          {taskLists.map((list) => {
            const numTasks = getNumTasks(list)
            const taskColor = numTasks === 0 ? 'text-error' : ''
            return (
              <tr key={list.id} className='even:bg-gray-100'>
                <td className='px-5 py-2'>
                  <div>
                    <div className='text-sm text-gray-900'>{list.name}</div>
                    <div className='text-xs text-gray-500'>{list.description}</div>
                  </div>
                </td>
                <td className={`p-0 align-middle ${taskColor}`}>{numTasks}</td>
                <td className='p-0 align-middle'>
                  <Toggle enabled={list.isDefault || false} setEnabled={onSetIsDefault(list)} />
                </td>
                <td className='align-middle p-0'>
                  <div className='flex flex-row justify-center items-center'>
                    <IconManage onClick={onManageTasklist(list)} className='mr-2' />
                    <IconTrash onClick={onDeleteTemplate(list)} />
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}
