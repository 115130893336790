import React from 'react'
import { ManageItemSlideOut as LibSlideOut } from 'theorem-lib/src/components/organisms/ManageItems/ManageItemSlideOut'
import { LibItemTemplate } from 'theorem-lib/src/entities/types/Spaces'
import { CreateItemTemplateInput, UpdateItemTemplateInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'

export const ManageItemSlideOut = () => {
  const { displayModalAction, displaySlideOutAction, resetCurrentSpaceTemplate, updateItemTemplate } = useActions()
  const { itemCategories, selectedItemTemplate } = useAppState()

  const onClose = () => {
    displaySlideOutAction('None')
    resetCurrentSpaceTemplate()
  }

  const onDiscard = () => {
    displayModalAction('GenericCancelModal')
  }

  const onSubmit = async (updatedItem: LibItemTemplate) => {
    if (updatedItem.id !== undefined) {
      const updatedItemInput: UpdateItemTemplateInput = {
        category: updatedItem.category?.id || '',
        highPrice: updatedItem.highPrice,
        id: updatedItem.id,
        image: updatedItem.image,
        lowPrice: updatedItem.lowPrice,
        midPrice: updatedItem.midPrice,
        name: updatedItem.name,
      }

      await updateItemTemplate(updatedItemInput)
    }

    resetCurrentSpaceTemplate()
    displaySlideOutAction('None')
  }

  if (selectedItemTemplate !== undefined) {
    const convertedItem: LibItemTemplate = {
      ...selectedItemTemplate,
      image: selectedItemTemplate.image || '',
      quantity: selectedItemTemplate.quantity || 0,
    }
    return (
      <LibSlideOut
        item={convertedItem}
        totalCategories={itemCategories}
        onClose={onClose}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        isCreate={false}
      />
    )
  } else {
    return <></>
  }
}
