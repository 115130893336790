import { yupResolver } from '@hookform/resolvers/yup'
import { groupBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import Select from 'react-select'
import PlusIcon from '../../../assets/icons/plus.svg'
import TrashIcon from '../../../assets/icons/trash.svg'
import UploadIcon from '../../../assets/icons/upload.svg'
import { LibIndustry, LibItemTemplate, LibSpaceItem, LibSpaceTemplate } from '../../../entities/types/Spaces'
import { uploadAvatarImage } from '../../../gateways/FileStoreGateway'
import { SpaceFormValidator } from '../../../validators/spaceFormValidator'
import { AvatarCircle } from '../../atoms'
import Card from '../../atoms/Card/Card'
import { ImageCropper } from '../../atoms/ImageCropper/ImageCropper'
import TextInput from '../../atoms/Input/TextInput'
import { customStyles, IconOption, IconSingleValue } from '../../atoms/ReactSelectStyling/selectStyle'
import { SlideOut } from '../../atoms/SlideOut/SlideOut'
import { SlideOutFooter } from '../../atoms/SlideOut/SlideoutFooter'

type itemObject = {
  totalLowPrice?: number
  totalMidPrice?: number
  totalHighPrice?: number
  itemId?: string
}

type ManageSpaceFormProps = {
  discountPercentage: number
  increasePercentage: number
  space: LibSpaceTemplate
  totalItems: LibItemTemplate[]
  industries: LibIndustry[] | undefined
  isTemplate: boolean
  isCreate: boolean
  isTheorem: boolean
  onSubmit: (space: LibSpaceTemplate & { newItems: LibItemTemplate[] }) => void
  onClose: () => void
  onDiscard: () => void
}

export function ManageSpaceSlideOut(props: ManageSpaceFormProps) {
  const [canSave, setCanSave] = useState(true)
  const [imageUrl, setImageUrl] = useState<string | undefined>(props.space.image)
  const [currentIndustry, setCurrentIndustry] = useState<LibIndustry | undefined>(props.space.industry)

  const industryList = useMemo(() => {
    if (props.industries !== undefined) {
      return props.industries
    }
    if (props.space.industry !== undefined) {
      return [props.space.industry]
    }
    return []
  }, [props.industries, props.space.industry])

  const newItem: LibItemTemplate = {
    createdOn: new Date(),
    discountPercentage: props.discountPercentage,
    highPrice: 0,
    id: '',
    increasePercentage: props.increasePercentage,
    lowPrice: 0,
    midPrice: 0,
    modifiedOn: undefined,
    name: '',
    quantity: 1,
  }

  const initialItems: LibSpaceItem[] = []
  const initialValues: LibSpaceTemplate & { newItems: LibSpaceItem[] } = { ...props.space, newItems: initialItems }
  const { control, formState: { errors, isDirty }, handleSubmit, register, setValue, trigger } = useForm<
    LibSpaceTemplate & { newItems: LibItemTemplate[] }
  >({
    defaultValues: initialValues,
    resolver: yupResolver(SpaceFormValidator(!props.isTemplate)),
  })

  const itemsFields = useFieldArray({ control, name: 'items' })
  const newItemsFields = useFieldArray({ control, name: 'newItems' })

  const append = newItemsFields.append
  const fields = newItemsFields.fields
  const removeNewItems = newItemsFields.remove

  const items = props.totalItems

  const selectFieldOptions = groupBy(items, i => i.category?.name)

  const itemsTemplates: itemObject[] = []

  items.map(itemTemplate => {
    const item: itemObject = {
      itemId: itemTemplate.id,
      totalHighPrice: itemTemplate.highPrice,
      totalLowPrice: itemTemplate.lowPrice,
      totalMidPrice: itemTemplate.midPrice,
    }
    itemsTemplates.push(item)
  })

  useEffect(() => {
    const totalItems = itemsFields.fields.length + newItemsFields.fields.length
    if (totalItems === 0) {
      setCanSave(false)
    } else {
      setCanSave(true)
    }
  }, [itemsFields.fields.length, newItemsFields.fields.length])

  const onDeleteImage = useCallback(() => {
    setImageUrl(undefined)
    setValue('image', undefined)
  }, [setImageUrl])

  const handleNewItemChange = (value: any, index: number) => {
    const newItem: LibSpaceItem = {
      discountPercentage: props.discountPercentage,
      highPrice: value.description[0].totalHighPrice,
      id: value.description[0].itemId,
      increasePercentage: props.increasePercentage,
      itemTemplatesId: value.description[0].itemId,
      lowPrice: value.description[0].totalLowPrice,
      midPrice: value.description[0].totalMidPrice,
      name: value.label,
      quantity: 1,
    }

    setValue(`newItems.${index}`, newItem)
    trigger([`newItems.${index}`])
  }

  const handleIndustryChange = useCallback((newIndustry: LibIndustry) => {
    setCurrentIndustry(newIndustry)
    setValue('industry', newIndustry)
  }, [setCurrentIndustry, setValue])

  const onSetImageUrl = useCallback(async (file: File) => {
    const url = await uploadAvatarImage(file)
    setImageUrl(url)
    setValue('image', url)
    trigger('image')
  }, [setImageUrl, trigger])

  const hasImage = imageUrl && imageUrl !== ''

  return (
    <SlideOut
      title={props.isCreate ? 'Create Space' : 'Manage Space'}
      onClose={() => isDirty ? props.onDiscard() : props.onClose()}
    >
      {!props.isTheorem && (
        <p className='mb-6 text-primary-200 font-normal'>
          Edit and save your space to make it the perfect combination of items for your project.
        </p>
      )}
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <div className='flex flex-row mb-6'>
          <AvatarCircle className='mr-6' url={imageUrl || ''} sizeRem={5} />
          <div className={`flex flex-col ${!hasImage ? 'justify-end' : ''}`}>
            <label htmlFor='cropper' className='text-cta-200 cursor-pointer font-normal'>
              <div className={`flex flex-row ${hasImage ? 'mt-3' : ''}`}>
                <div className='mr-3 text-teal-300'>
                  <UploadIcon />
                </div>
                <div className={`${hasImage ? 'mb-3' : ''} text-teal-300 font-normal`}>Upload Image</div>
              </div>
            </label>
            {hasImage
              && (
                <button onClick={onDeleteImage}>
                  <div className='flex flex-row'>
                    <div className='mr-3 text-teal-300'>
                      <TrashIcon />
                    </div>
                    <div className='mb-6 text-teal-300 font-normal'>Delete Image</div>
                  </div>
                </button>
              )}
            <ImageCropper
              size={300}
              setCroppedImageFile={onSetImageUrl}
            />
          </div>
        </div>
        <span className='flex flex-grow justify-end text-error font-medium text-xs'>{errors.image?.message}</span>
        <div className='flex flex-row'>
          <div className='w-full'>
            <div className='mb-6'>
              <TextInput label='Name' labelClass='text-gray-600' hasAutoFocus {...register('name')} />
              <span className='flex flex-grow justify-end text-error font-medium text-xs'>{errors.name?.message}</span>
            </div>
          </div>
          {!props.isTemplate && (
            <div className='ml-4 w-1/6'>
              <div className='mb-6'>
                <TextInput label='Qty' type='number' min='0' hasAutoFocus {...register('quantity')} />
                <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                  {errors.quantity?.message}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className='mb-10 border-b border-primary-400'>
          {!props.isCreate
            && <label className='text-xs ml-2'>Industry</label>}
          <Select
            className=''
            placeholder='Industry'
            options={[{
              options: industryList.map(industry => ({
                description: industry.name,
                label: industry.name,
                value: industry,
              })),
            }]}
            styles={{
              ...customStyles,
              control: (base: any, state: { isFocused: any }) => ({
                ...base,
                border: state.isFocused ? 0 : 0,
                boxShadow: state.isFocused ? 0 : 0,
                height: 40,
                width: '100%',
              }),
            }}
            onChange={(val) => {
              if (val && val.value) {
                handleIndustryChange(val.value)
              }
            }}
            value={currentIndustry
              ? {
                description: currentIndustry.name,
                label: currentIndustry.name,
                value: currentIndustry,
              }
              : undefined}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                neutral10: 'none',
                primary: 'lightblue',
              },
            })}
          />
          <span className='flex flex-grow justify-end text-error font-medium text-xs'>
            {errors.industry?.id?.message}
          </span>
        </div>
        <div className='mb-10'>
          <TextInput
            label='Description'
            labelClass='text-gray-600'
            maxLength={100}
            hasAutoFocus
            {...register('description')}
          />
          <span className='flex flex-grow justify-end text-error font-medium text-xs'>
            {errors.description?.message}
          </span>
        </div>
        <section>
          <h2 className='text-lg font-medium text-gray-900'>Items</h2>
          {!props.isTheorem
            && (
              <p className='my-4 text-primary-200 font-normal'>
                Choose from a list of pre-defined items to make the perfect space. You must have at least one item in a
                space.
              </p>
            )}
          <ul className='flex flex-col'>
            {itemsFields.fields.map((_, index) => (
              <Card key={index} className='mt-3 p-3'>
                <li className='flex flex-row mt-5'>
                  <div>
                    <label htmlFor='name'>Name</label>
                    <input
                      id='name'
                      placeholder=''
                      type='text'
                      className='w-50'
                      {...register(`items.${index}.name`)}
                    />
                    <span className='flex flex-grow text-error font-medium text-xs'>
                      {(errors.items as any)?.[index]?.quantity?.message}
                    </span>
                  </div>
                  <div className='mx-6 relative'>
                    <label htmlFor='quantity'>Qty</label>
                    <input
                      id='quantity'
                      placeholder=' '
                      type='number'
                      min='0'
                      className='w-32'
                      {...register(`items.${index}.quantity`)}
                    />
                    <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                      {(errors.items as any)?.[index]?.quantity?.message}
                    </span>
                  </div>

                  {props.isTemplate && (
                    <div className='align-text-baseline relative'>
                      <button
                        className='cursor-pointer fill-[#2b5b74] rounded-lg w-10 h-10 absolute bottom-0'
                        type='button'
                        onClick={() => {
                          itemsFields.remove(index)
                        }}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  )}
                </li>
                {!props.isTemplate && (
                  <div className='flex-col mt-1'>
                    <div className='flex flex-row'>
                      <div className='flex flex-row mr-4'>
                        <label htmlFor='lowPrice' className='mt-4 mr-2'>
                          L:
                        </label>
                        <input
                          id='lowPrice'
                          placeholder=' '
                          type='number'
                          min='0'
                          className='w-28'
                          {...register(`items.${index}.lowPrice`)}
                        />
                        <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                          {(errors.items as any)?.[index]?.lowPrice?.message}
                        </span>
                      </div>

                      <div className='flex flex-row mr-2'>
                        <label htmlFor='midPrice' className='mt-4 mr-2'>
                          M:
                        </label>
                        <input
                          id='midPrice'
                          placeholder=' '
                          type='number'
                          min='0'
                          className='w-28'
                          {...register(`items.${index}.midPrice`)}
                        />
                        <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                          {(errors.items as any)?.[index]?.midPrice?.message}
                        </span>
                      </div>

                      <div className='flex flex-row'>
                        <label htmlFor='highPrice' className='mt-4 mr-2'>
                          H:
                        </label>
                        <input
                          id='highPrice'
                          placeholder=' '
                          type='number'
                          min='0'
                          className='w-28'
                          {...register(`items.${index}.highPrice`)}
                        />
                        <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                          {(errors.items as any)?.[index]?.highPrice?.message}
                        </span>
                      </div>
                      <div className='ml-6 inline-block align-text-baseline'>
                        <button
                          className='cursor-pointer fill-[#2b5b74] rounded-lg w-10 h-10 '
                          type='button'
                          onClick={() => {
                            itemsFields.remove(index)
                          }}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </Card>
            ))}
            {fields.map((field, index) => (
              <li key={field.id} className='flex flex-row items-baseline mt-9 space-x-6'>
                <div>
                  <label className='border-b border-primary-100'>
                    <span className='text-gray-600'>Name</span>
                    <Select
                      menuPlacement='top'
                      placeholder='Select an Item'
                      options={Object.keys(selectFieldOptions).map(category => ({
                        label: category,
                        options: selectFieldOptions[category].map(item => ({
                          description: itemsTemplates.filter(
                            itemsTemplate => itemsTemplate.itemId === item.id,
                          ),
                          image: item.image,
                          label: item.name,
                          value: item.id,
                        })),
                      }))}
                      styles={{
                        ...customStyles,
                        control: (base: any, state: { isFocused: any }) => ({
                          ...base,
                          border: state.isFocused ? 0 : 0,
                          boxShadow: state.isFocused ? 0 : 0,
                          height: 40,
                          width: 290,
                        }),
                      }}
                      onChange={(val) => handleNewItemChange(val, index)}
                      components={{ Option: IconOption, SingleValue: IconSingleValue }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          neutral10: 'none',
                          primary: 'lightblue',
                        },
                      })}
                    />
                  </label>
                  <span className='flex flex-grow text-error font-medium text-xs'>
                    {errors.newItems?.[index]?.name?.message}
                  </span>
                </div>
                <div className='relative'>
                  <label className='text-gray-600' htmlFor='quantity'>Qty</label>
                  <input
                    id='quantity'
                    placeholder=' '
                    type='number'
                    min='0'
                    className='w-32'
                    {...register(`newItems.${index}.quantity`)}
                  />
                  <span className='flex flex-grow justify-end text-error font-medium text-xs'>
                    {errors.newItems?.[index]?.quantity?.message}
                  </span>
                </div>
                <div className='flex items-center self-stretch'>
                  <button
                    className='cursor-pointer fill-[#2b5b74] button rounded-lg px-2 py-2 bg-blue-100 '
                    type='button'
                    onClick={() => {
                      removeNewItems(index)
                    }}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <button type='button' className='text-cta-200 flex flex-row my-4' onClick={() => append(newItem)}>
            <PlusIcon />
            <span>Add item</span>
          </button>
        </section>
        <SlideOutFooter>
          <button
            className={`bg-cta-200 hover:bg-cta-300 text-white p-3 rounded-lg font-semibold mr-2 ${
              canSave ? '' : 'opacity-25'
            }`}
            type='submit'
            disabled={!canSave}
          >
            {props.isCreate ? 'Create' : 'Save'}
          </button>
        </SlideOutFooter>
      </form>
    </SlideOut>
  )
}
