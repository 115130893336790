import React, { useCallback } from 'react'
import ArrowRight from 'theorem-lib/src/assets/icons/arrow-right.svg'
import PlusIcon from 'theorem-lib/src/assets/icons/plus.svg'
import { Button } from 'theorem-lib/src/components/atoms/Button/Button'
import { useActions, useAppState } from '../../../../presenter'
import { TaskListTable } from './TasklistTable/tasklistTable'

export function Workbooks() {
  const { displaySlideOutAction } = useActions()
  const { taskLists } = useAppState()

  const onCreateList = useCallback(async () => {
    displaySlideOutAction('CreateList')
  }, [displaySlideOutAction])

  return (
    <>
      <div className='flex flex-row flex-shrink justify-end'>
        <button
          title='Add task list'
          className='w-10 h-10 rounded-md flex justify-center items-center bg-teal-300 hover:bg-teal-400'
          onClick={onCreateList}
        >
          <PlusIcon className='text-white' />
        </button>
      </div>

      <div className='flex grow'>
        {taskLists.length > 0
          ? <TaskListTable taskLists={taskLists} />
          : (
            <div className='flex flex-1 flex-col justify-center items-center'>
              <div className='border-primary-100 rounded-full border-2 w-12 h-12 flex justify-center items-center mb-4'>
                <div className='-rotate-45'>
                  <ArrowRight />
                </div>
              </div>
              <h4 className='text-xl font-semibold text-primary-100 mb-0.5 text-center'>
                You don&apos;t have any task lists yet
                <br />
                Add a list to get started.
              </h4>
              <p className='mb-6 text-primary-200'>All task lists you create will be displayed here</p>

              <Button onClick={onCreateList}>Add New Task List</Button>
            </div>
          )}
      </div>
    </>
  )
}
