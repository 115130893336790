import { createOvermind, IContext } from 'overmind'
import { createActionsHook, createStateHook } from 'overmind-react'
import { uploadAvatarImage } from 'theorem-lib/src/gateways/FileStoreGateway'
import { getTenants, updateTenant } from '../gateways/TenantsGateway'
import {
  addRoleToUser,
  adminUpdateUser,
  getAuthenticatedUser,
  getTheoremUsers,
  inviteUser,
  reInviteUser,
  removeRoleFromUser,
  updateAuthenticatedUser,
  updateUser,
} from '../gateways/UserGateway'
import {
  bootstrapStateAction,
  displayDashboardAction,
  displayDeleteModalAction,
  displayModalAction,
  displayNotFoundAction,
  displayPreDefinedContentAction,
  displaySlideOutAction,
  redirectAction,
} from './actions'
import {
  createItemTemplate,
  createSpaceTemplate,
  deleteItemAction,
  deleteSpaceAction,
  fetchIndustries,
  fetchItemTemplateById,
  fetchItemTemplateCategories,
  fetchItemTemplates,
  fetchSpaceTemplateById,
  getItemTemplatesAction,
  getSpaceTemplateByIdAction,
  getSpaceTemplatesAction,
  resetActiveSpaceTemplate,
  resetCurrentSpaceTemplate,
  updateItemTemplate,
  updateSpaceTemplateAction,
} from './actions/spaces_actions'
import { getTenantsManagementAction } from './actions/tenants_actions'
import {
  displayUserEditAction,
  displayUserManagementAction,
  reinviteUserAction,
  searchUser,
  setSelectedTenant,
  submitInviteNewUserAction,
  submitUserEditAction,
  toggleAdminAction,
  updateTenantInformation,
  updateUserEditAction,
  updateUserStatusAction,
  uploadAvatarImageAction,
  userRemoveAvatarAction,
} from './actions/user_actions'
import {
  createTasklistTemplate,
  createTaskTemplate,
  deleteSectionTemplate,
  deleteTasklistTemplate,
  deleteTaskTemplate,
  getAllTasklistTemplates,
  manageTasklistTemplate,
  manageTasklistTemplateById,
  setTasklistTemplateDefault,
  updateTasklistTemplate,
  updateTaskTemplate,
  upsertSectionTemplate,
} from './actions/workbook_actions'
import { redirect } from './router'
import { coreState, spaceState, tenantState, userState, workbookState } from './state'

const overmindConfig = {
  actions: {
    bootstrapStateAction,
    createItemTemplate,
    createSpaceTemplate,
    createTaskTemplate,
    createTasklistTemplate,
    deleteItemAction,
    deleteSectionTemplate,
    deleteSpaceAction,
    deleteTaskTemplate,
    deleteTasklistTemplate,
    displayDashboardAction,
    displayDeleteModalAction,
    displayModalAction,
    displayNotFoundAction,
    displayPreDefinedContentAction,
    displaySlideOutAction,
    displayUserEditAction,
    displayUserManagementAction,
    fetchIndustries,
    fetchItemTemplateById,
    fetchItemTemplateCategories,
    fetchItemTemplates,
    fetchSpaceTemplateById,
    getAllTasklistTemplates,
    getItemTemplatesAction,
    getSpaceTemplateByIdAction,
    getSpaceTemplatesAction,
    getTenantsManagementAction,
    manageTasklistTemplate,
    manageTasklistTemplateById,
    redirectAction,
    reinviteUserAction,
    resetActiveSpaceTemplate,
    resetCurrentSpaceTemplate,
    searchUser,
    setSelectedTenant,
    setTasklistTemplateDefault,
    submitInviteNewUserAction,
    submitUserEditAction,
    toggleAdminAction,
    updateItemTemplate,
    updateSpaceTemplateAction,
    updateTaskTemplate,
    updateTasklistTemplate,
    updateTenantInformation,
    updateUser,
    updateUserEditAction,
    updateUserStatusAction,
    uploadAvatarImageAction,
    upsertSectionTemplate,
    userRemoveAvatarAction,
  },
  effects: {
    addRoleToUser,
    adminUpdateUser,
    getAuthenticatedUser,
    getTenants,
    getTheoremUsers,
    inviteUser,
    reInviteUser,
    redirect,
    removeRoleFromUser,
    updateAuthenticatedUser,
    updateTenant,
    updateUser,
    uploadAvatarImage,
  },
  state: {
    ...coreState,
    ...userState,
    ...spaceState,
    ...workbookState,
    ...tenantState,
  },
}

export type Context = IContext<{
  state: typeof overmindConfig.state
  actions: typeof overmindConfig.actions
  effects: typeof overmindConfig.effects
}>

export const overmindApp = createOvermind(overmindConfig)

export const useAppState = createStateHook<Context>()
export const useActions = createActionsHook<Context>()
