import { flatMap } from 'lodash'
import { SectionTemplate, TaskField, TasklistTemplate, TaskTemplate } from '../../../../../generated/api'

export type ManagedItemTemplate = ManagedSectionTemplate | ManagedTaskTemplate

export type ManagedSectionTemplate = {
  __typename: 'ManagedSectionTemplate'
  id?: string
  name: string
  tasklistId: string
  tasks: ManagedTaskTemplate[]
}

export type ManagedTaskTemplate = {
  __typename: 'ManagedTaskTemplate'
  id?: string
  name: string
  sectionId: string
  fields: TaskField[]
}

export const taskTemplateToManagedTaskTemplate = (task: TaskTemplate): ManagedTaskTemplate => {
  return {
    __typename: 'ManagedTaskTemplate',
    fields: task.fields,
    id: task.id,
    name: task.name,
    sectionId: task.sectionId,
  }
}

export const sectionTemplateToManageSectionTemplate = (section: SectionTemplate): ManagedSectionTemplate => {
  return {
    __typename: 'ManagedSectionTemplate',
    id: section.id,
    name: section.name,
    tasklistId: section.tasklistId,
    tasks: section.tasks ? section.tasks.map(taskTemplateToManagedTaskTemplate) : [],
  }
}

export const tasklistTemplateToManagedItems = (list?: TasklistTemplate): ManagedItemTemplate[] => {
  if (list === undefined || list.sections === undefined) {
    return []
  }
  const items = flatMap(list.sections, (section) => {
    const taskList = section.tasks?.map(task => taskTemplateToManagedTaskTemplate(task)) ?? []
    const itemList = [sectionTemplateToManageSectionTemplate(section), ...taskList]
    return itemList
  })

  return items
}

export const isTaskTemplate = (item: ManagedItemTemplate): boolean => {
  if (item.__typename === 'ManagedTaskTemplate') {
    return true
  }

  return false
}

export const isSectionTemplate = (item: ManagedItemTemplate): boolean => {
  if (item.__typename === 'ManagedSectionTemplate') {
    return true
  }

  return false
}

export const getSectionTemplate = (item: ManagedItemTemplate): ManagedSectionTemplate | undefined => {
  if (item.__typename === 'ManagedSectionTemplate') {
    return item
  }
  return undefined
}

export const getTaskTemplate = (item: ManagedItemTemplate): ManagedTaskTemplate | undefined => {
  if (item.__typename === 'ManagedTaskTemplate') {
    return item
  }
  return undefined
}

export const isCreated = (item: ManagedItemTemplate): boolean => item.id === undefined || item.id === ''
