import * as Yup from 'yup'

const errorMessage = 'Please fill in the required field'

export const ItemFormValidator = () => {
  return Yup.object().shape({
    category: Yup.object().shape({
      id: Yup.string().required('a category is required'),
    }),
    highPrice: Yup.number().typeError('high price must be a number').min(0, 'high price cannot be negative'),
    lowPrice: Yup.number().typeError('low price must be a number').min(0, 'low price cannot be negative'),
    midPrice: Yup.number().typeError('mid price must be a number').min(0, 'mid price cannot be negative'),
    name: Yup.string().required(errorMessage),
  })
}
