import { Switch } from '@headlessui/react'
import classNames from 'classnames'
import React from 'react'

type ToggleProps = {
  enabled: boolean
  setEnabled: (enabled: boolean) => void
}

export function Toggle(props: ToggleProps) {
  return (
    <>
      <Switch
        checked={props.enabled}
        onChange={props.setEnabled}
        className={classNames(
          props.enabled ? 'bg-success' : 'bg-gray-400',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-100',
        )}
      >
        <span className='sr-only'>Use setting</span>
        <span
          aria-hidden='true'
          className={classNames(
            props.enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
          )}
        />
      </Switch>
    </>
  )
}
