/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import CaseIcon from 'theorem-lib/src/assets/icons/case.svg'
import ClaimIcon from 'theorem-lib/src/assets/icons/claim.svg'
import CogIcon from 'theorem-lib/src/assets/icons/cog.svg'
import HomeIcon from 'theorem-lib/src/assets/icons/home.svg'
import TenantsIcon from 'theorem-lib/src/assets/icons/members.svg'
import QuestionMarkIcon from 'theorem-lib/src/assets/icons/question-mark.svg'
import { SidebarIcons } from 'theorem-lib/src/entities/enums/SidebarIcons'
import { LibNavOption } from 'theorem-lib/src/entities/types/NavOption'
import SearchChairIcon from '../../../assets/icons/search-chair-icon.svg'
import SlackIcon from '../../../assets/icons/slack-icon.svg'
import AvatarCircle from '../../atoms/AvatarCircle/AvatarCircle'
import AvatarInitial from '../../atoms/AvatarInitial/AvatarInitial'

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ')
}

type SidebarProps = {
  avatar: string
  name: {
    first: string
    last: string
  }
  navOptions: LibNavOption[]
  children: React.ReactNode
  tenantInfo?: {
    defaultLogoUrl: string
    logoUrl: string
  }
  PlatformLogo?: React.ReactNode
}

export function Sidebar({ PlatformLogo, ...props }: SidebarProps): React.ReactElement {
  const activeScreen = props.navOptions.find((screen) => screen.current)
  const handleLogoImageError = (e: React.ChangeEvent<HTMLImageElement>) => {
    e.target.src = props.tenantInfo?.defaultLogoUrl ?? ''
  }

  return (
    <>
      <div>
        {/* Static sidebar for desktop */}
        <div className='hidden md:flex md:w-24 md:flex-col md:fixed md:inset-y-0 z-50'>
          <div className='flex-1 flex flex-col min-h-0 bg-sidebar'>
            <div className='flex-1 flex flex-col pt-5 pb-4 overflow-y-auto'>
              {props.tenantInfo
                ? (
                  <img
                    className='w-16 m-auto'
                    src={props.tenantInfo.logoUrl}
                    alt='logo'
                    onError={handleLogoImageError}
                  />
                )
                : <div>{PlatformLogo && PlatformLogo}</div>}
              <nav className='mt-5 flex-1 space-y-1'>
                {props.navOptions.map((item) => (
                  <div key={item.name}>
                    {item.name !== 'Profile'
                      ? (
                        <a
                          href={item.href}
                          target={('Help' === item.name) || ('MRL' === item.name) || ('INDEAL' === item.name)
                              || ('Slack' === item.name)
                            ? '_blank'
                            : ''}
                          rel={('Help' === item.name) || ('MRL' === item.name) || ('INDEAL' === item.name)
                              || ('Slack' === item.name)
                            ? 'noopener noreferrer'
                            : ''}
                          className={classNames(
                            (item.current || window.location.pathname === item.href)
                              ? 'menu-item-active'
                              : 'menu-item',
                            'group flex items-center text-center py-2 text-sm font-medium flex-col',
                          )}
                        >
                          {item.icon == SidebarIcons.Home && <HomeIcon />}
                          {item.icon == SidebarIcons.Case && <CaseIcon />}
                          {item.icon == SidebarIcons.Cog && <CogIcon />}
                          {item.icon == SidebarIcons.Help && <QuestionMarkIcon />}
                          {item.icon == SidebarIcons.Claim && <ClaimIcon />}
                          {item.icon == SidebarIcons.Tenants && <TenantsIcon />}
                          {item.icon == SidebarIcons.MRLIndeal && <SearchChairIcon />}
                          {item.icon == SidebarIcons.Slack && <SlackIcon />}
                          <div>{item.name}</div>
                        </a>
                      )
                      : (
                        <div className='flex-shrink-0 flex pt-4'>
                          <a
                            href={item.href}
                            title='Profile, Password, and Logout'
                            className='profile block absolute bottom-[1rem]'
                          >
                            <div
                              className={classNames(
                                item.current
                                  ? 'menu-item-active'
                                  : 'menu-item',
                                'flex-shrink-0 w-full group pt-2 ',
                              )}
                            >
                              <div className='flex items-center flex-col'>
                                {props?.avatar ? <AvatarCircle url={props.avatar} /> : (
                                  <AvatarInitial
                                    firstName={props.name.first}
                                    lastName={props.name.last}
                                  />
                                )}
                                <div className='text-center'>
                                  <p className='text-sm font-medium my-2 px-2'>
                                    {props.name.first} {props.name.last}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      )}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
        {/* Navbar for mobile */}
        <div className='md:hidden flex flex-row bg-primary-100 h-14 grow p-3 items-center justify-between'>
          {activeScreen && (
            <div className='text-white flex flex-row'>
              <HomeIcon />
              <h1 className='ml-3.5 font-semibold'>{activeScreen.name}</h1>
            </div>
          )}
          <div className='flex flex-row'>
            {props?.avatar ? <AvatarCircle url={props.avatar} /> : (
              <AvatarInitial
                firstName={props.name.first}
                lastName={props.name.last}
              />
            )}
          </div>
        </div>
        <div className='md:pl-24 flex flex-col flex-1'>
          <main className='flex-1'>
            <div className='py-6 mx-auto px-4 sm:px-6 md:px-8 min-h-screen h-screen'>
              {props.children}
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
