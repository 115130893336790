import React, { ChangeEventHandler, forwardRef, KeyboardEvent } from 'react'

type TextInputProps = {
  hasAutoFocus?: boolean
  value?: string
  iconLeft?: string
  iconRight?: string
  type?: string
  label?: string
  labelClass?: string
  inputClass?: string
  name?: string
  maxLength?: number
  prefix?: string
  defaultValue?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: ChangeEventHandler<HTMLInputElement>
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
}

const TextInput = (
  {
    defaultValue,
    hasAutoFocus,
    iconLeft: IconLeft,
    iconRight: IconRight,
    inputClass,
    label,
    labelClass,
    onBlur,
    onKeyDown,
    onKeyUp,
    prefix,
    type,
    ...rest
  }: TextInputProps,
  ref: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement => {
  return (
    <div className='border-b border-primary-400 flex flex-row grow relative mt-2'>
      {!!IconLeft && (
        <div className='flex items-center justify-center w-10 h-10'>
          <IconLeft />
        </div>
      )}
      {prefix
        && (
          <div className='flex flex-col justify-center'>
            <div>$</div>
          </div>
        )}
      <input
        type={type ? type : 'text'}
        autoFocus={hasAutoFocus}
        defaultValue={defaultValue}
        className={`${inputClass} border-none focus:ring-0 grow w-full`}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        placeholder=' '
        id={rest.name}
        ref={ref}
        step='any'
        {...rest}
      />
      {label && rest.name && <label className={`pointer-events-none ${labelClass}`} htmlFor={rest.name}>{label}</label>}
      {!!IconRight && (
        <div className='flex items-center justify-center w-10 h-10'>
          <IconRight />
        </div>
      )}
    </div>
  )
}

export default forwardRef(TextInput)
