import { toast } from 'react-toastify'
import { InviteUser } from '../../../../../libs/client/src/components/organisms/Users/AddUserSlideOut'
import { SecurityRole, Status, Tenant, UpdateTenantDetailsInput, User } from '../../generated/api'
import { setActiveScreen } from '../../helpers/setActiveScreen'
import { Context } from '../../presenter'
import { RouteNames } from '../router'
import { defaultErrorHandler } from './index'

export async function displayUserEditAction(context: Context) {
  context.state.currentPage = 'Loading'
  try {
    context.state.userEdit = await context.effects.getAuthenticatedUser()
    context.state.currentPage = 'UserEdit'
    setActiveScreen(context, 'Profile')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayUserManagementAction(context: Context) {
  context.state.currentPage = 'Loading'
  try {
    context.state.users = await context.effects.getTheoremUsers()
    context.state.currentPage = 'UserManagement'
    context.state.userSearchString = ''
    setActiveScreen(context, 'Admin')
  } catch (err) {
    console.log(JSON.stringify(err, null, 2))
    defaultErrorHandler(context, err)
  }
}

export async function displayDashboardAction(context: Context) {
  try {
    if (!context.state.lockCurrentPageChange) {
      context.state.currentPage = 'Dashboard'
      setActiveScreen(context, RouteNames.Dashboard)
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function displayPreDefinedContentAction(context: Context) {
  try {
    context.state.currentPage = 'PreDefinedContent'
    setActiveScreen(context, RouteNames.PreDefinedContent)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export function displayModalAction(
  context: Context,
  modalName: 'None',
) {
  context.state.currentModal = modalName
}

export async function updateUserStatusAction(context: Context, value: { user: User; status: Status }) {
  try {
    value.user.status = value.status
    await context.effects.adminUpdateUser(value.user)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function submitInviteNewUserAction(context: Context, values: InviteUser) {
  try {
    await context.effects.inviteUser(values.email, values.firstName)
    context.state.users = await context.effects.getTheoremUsers()
    toast('Invitation has been sent successfully')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function updateTenantInformation(context: Context, input: UpdateTenantDetailsInput) {
  try {
    const resultTenant = await context.effects.updateTenant(input)
    const newData = context.state.tenants.map((tenant) => {
      if (tenant.subDomain === resultTenant.subDomain) {
        return {
          ...resultTenant,
        }
      } else {
        return tenant
      }
    })

    context.state.tenants = newData
    toast('Tenant Successfully Updated')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function setSelectedTenant(context: Context, tenant: Tenant) {
  try {
    context.state.selectedTenant = { ...tenant }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function reinviteUserAction(context: Context, value: { user: User }) {
  try {
    await context.effects.reInviteUser(value.user)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function toggleAdminAction(context: Context, value: { user: User }) {
  try {
    const { user } = value
    if (user.securityRoles?.some(role => role === SecurityRole.Admin)) {
      user.securityRoles = value.user.securityRoles?.filter(role => role !== SecurityRole.Admin)
      await context.effects.removeRoleFromUser(user, SecurityRole.Admin)
    } else {
      value.user.securityRoles?.push(SecurityRole.Admin)
      await context.effects.addRoleToUser(user, SecurityRole.Admin)
    }
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function searchUser(context: Context, value: string) {
  context.state.userSearchString = value
}

export async function updateUserEditAction(context: Context, value: { user: User }) {
  context.state.userEdit = value.user
}

export async function submitUserEditAction(context: Context, user: User) {
  try {
    await context.effects.updateAuthenticatedUser(user)
    context.state.authenticatedUser = await context.effects.getAuthenticatedUser()
    context.state.userEdit = { ...context.state.authenticatedUser }
    toast('Changes have been saved successfully.')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function userRemoveAvatarAction(context: Context) {
  const u = { ...context.state.authenticatedUser }
  u.avatarUrl = ''
  try {
    await context.effects.updateAuthenticatedUser(u)
    context.state.authenticatedUser = await context.effects.getAuthenticatedUser()
    context.state.userEdit = { ...context.state.authenticatedUser }
    toast('Changes have been saved successfully.')
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}

export async function uploadAvatarImageAction(
  context: Context,
  value: { avatarFile: File; user: User },
) {
  try {
    const avatarUrl = await context.effects.uploadAvatarImage(value.avatarFile)
    const newUser = { ...value.user }
    newUser.avatarUrl = avatarUrl
    context.actions.submitUserEditAction(newUser)
  } catch (err) {
    defaultErrorHandler(context, err)
  }
}
