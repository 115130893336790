import React, { useMemo } from 'react'
import { ManageSpaceSlideOut as LibSlideOut } from 'theorem-lib/src/components/organisms/ManageSpaces/ManageSpaceSlideOut'
import { LibIndustry, LibItemTemplate, LibSpaceTemplate } from 'theorem-lib/src/entities/types/Spaces'
import {
  CreateSpaceTemplateInput,
  SpaceItemTemplate,
  SpaceTemplate,
  SpaceTemplateItemInput,
} from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'

const convertGqlSpaceTemplateToLibTemplate = (template: SpaceTemplate): LibSpaceTemplate => {
  return {
    ...template,
    description: template.description || '',
    image: template.image || '',
    industry: template.industry
      ? {
        ...template.industry,
        covers: template.industry.covers || undefined,
      }
      : undefined,
    items: template.items?.map((item) => {
      return {
        ...item,
        image: item.image || '',
        quantity: item.quantity || 0,
      }
    }) || [],
    quantity: 0,
  }
}

const convertGqlItemTemplatesToLibTemplate = (items: SpaceItemTemplate[]): LibItemTemplate[] => {
  return items.map((item) => {
    return {
      ...item,
      image: item.image ?? '',
      quantity: item.quantity ?? 0,
    }
  })
}

export const CreateSpaceSlideOut = () => {
  const { createSpaceTemplate, displayModalAction, displaySlideOutAction, resetCurrentSpaceTemplate } = useActions()
  const { industryList, itemTemplates } = useAppState()

  const onClose = () => {
    displaySlideOutAction('None')
    resetCurrentSpaceTemplate()
  }

  const onDiscard = () => {
    displayModalAction('GenericCancelModal')
  }

  const libIndustries: LibIndustry[] = useMemo(() => {
    return industryList.map((industry) => {
      return {
        ...industry,
        covers: industry.covers ? industry.covers : undefined,
      }
    })
  }, [industryList])

  const onSubmit = async (newSpace: LibSpaceTemplate & { newItems: LibItemTemplate[] }) => {
    const allItems = [
      ...newSpace.items,
      ...newSpace.newItems,
    ]

    const convertedItems: SpaceTemplateItemInput[] = allItems.filter((item) => item.id !== undefined).map((item) => {
      return {
        id: item.id!,
        quantity: item.quantity,
      }
    })

    const createdInput: CreateSpaceTemplateInput = {
      description: newSpace.description,
      image: newSpace.image,
      industry: newSpace.industry?.id,
      name: newSpace.name,
    }

    await createSpaceTemplate({ items: convertedItems, space: createdInput })
    resetCurrentSpaceTemplate()
    displaySlideOutAction('None')
  }

  const spaceTemplate: LibSpaceTemplate = {
    createdOn: new Date(),
    description: '',
    id: '',
    items: [],
    name: '',
    quantity: 0,
  }

  const totalItems: LibItemTemplate[] = convertGqlItemTemplatesToLibTemplate(itemTemplates)

  return (
    <LibSlideOut
      space={spaceTemplate}
      totalItems={totalItems}
      onClose={onClose}
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      industries={libIndustries}
      isTemplate={true}
      isCreate={true}
      isTheorem={true}
    />
  )
}
