import { ApolloClient, ApolloError, InMemoryCache, ServerError } from '@apollo/client'
import { cloneDeep } from 'lodash'
import config from '../config'
import {
  CreateTaskListTemplateInput,
  CreateTaskTemplateInput,
  DeleteTasklistTemplateDocument,
  SectionTemplate,
  TasklistTemplate,
  TaskTemplate,
  UpdateTasklistTemplateInput,
  UpdateTaskTemplateInput,
  UpsertSectionTemplateInput,
} from '../generated/api'
import { UnauthenticatedError, UnexpectedError } from '../presenter/errors'
import {
  CreateTasklistTemplateDocument,
  CreateTaskTemplateDocument,
  DeleteSectionTemplateDocument,
  DeleteTaskTemplateDocument,
  GetAllTasklistTemplatesDocument,
  UpdateTasklistTemplateDocument,
  UpdateTaskListTemplateIsDefaultDocument,
  UpdateTaskTemplateDocument,
  UpsertSectionTemplateDocument,
} from './documents/tasklists.document'

const client = new ApolloClient({
  cache: new InMemoryCache(),
  credentials: 'include',
  uri: config.theoremGraphqlUrl,
})

function isApolloError(err: unknown | ApolloError): err is ApolloError {
  return (err as ApolloError).graphQLErrors !== undefined
}

function isServerError(err: unknown | ServerError): err is ServerError {
  return (err as ServerError).name == 'ServerError'
}

function graphqlErrorHandler(err: unknown | ApolloError) {
  if (isApolloError(err)) {
    const networkError = err.networkError
    if (networkError && isServerError(networkError)) {
      if (networkError.statusCode == 401) throw new UnauthenticatedError()
      if (networkError.statusCode == 404) throw new UnexpectedError()
    } else {
      const graphQlErrors = (err as ApolloError).graphQLErrors
      if (graphQlErrors.length > 0) {
        if (graphQlErrors[0].message.startsWith('A space template already exists')) {
          throw new Error('A space already exists with that name')
        } else if (graphQlErrors[0].message.startsWith('An item template already exists')) {
          throw new Error('An item already exists with that name')
        }
      }
    }
  }
}

export const createTasklistTemplate = async (input: CreateTaskListTemplateInput): Promise<string> => {
  try {
    const { data } = await client.mutate({
      mutation: CreateTasklistTemplateDocument,
      variables: {
        input,
      },
    })

    return data.createTasklistTemplate
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const getTasklistTemplates = async (): Promise<TasklistTemplate[]> => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'no-cache',
      query: GetAllTasklistTemplatesDocument,
    })

    return cloneDeep(data.getTasklistTemplates)
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteTasklistTemplate = async (tasklistId: string): Promise<boolean> => {
  try {
    await client.mutate({
      mutation: DeleteTasklistTemplateDocument,
      variables: {
        id: tasklistId,
      },
    })

    return true
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateTasklistTemplate = async (updateInput: UpdateTasklistTemplateInput): Promise<boolean> => {
  try {
    await client.mutate({
      mutation: UpdateTasklistTemplateDocument,
      variables: {
        input: updateInput,
      },
    })

    return true
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateTasklistTemplateIsDefault = async (templateId: string, isDefault: boolean): Promise<boolean> => {
  try {
    await client.mutate({
      mutation: UpdateTaskListTemplateIsDefaultDocument,
      variables: {
        isDefault,
        templateId,
      },
    })

    return true
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const upsertSectionTemplate = async (input: UpsertSectionTemplateInput): Promise<SectionTemplate> => {
  try {
    const { data } = await client.mutate({
      mutation: UpsertSectionTemplateDocument,
      variables: {
        input,
      },
    })

    return data.upsertSectionTemplate
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteSectionTemplate = async (templateId: string): Promise<boolean> => {
  try {
    await client.mutate({
      mutation: DeleteSectionTemplateDocument,
      variables: {
        id: templateId,
      },
    })

    return true
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const deleteTaskTemplate = async (taskTemplateId: string): Promise<boolean> => {
  try {
    await client.mutate({
      mutation: DeleteTaskTemplateDocument,
      variables: {
        id: taskTemplateId,
      },
    })

    return true
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateTaskTemplate = async (input: UpdateTaskTemplateInput): Promise<TaskTemplate> => {
  try {
    const { data } = await client.mutate({
      mutation: UpdateTaskTemplateDocument,
      variables: {
        input,
      },
    })

    return data.updateTaskTemplate
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const createTaskTemplate = async (input: CreateTaskTemplateInput): Promise<TaskTemplate> => {
  try {
    const { data } = await client.mutate({
      mutation: CreateTaskTemplateDocument,
      variables: {
        input,
      },
    })

    return data.createTaskTemplate
  } catch (err) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
