import React from 'react'
import { ManageItemSlideOut as LibSlideOut } from 'theorem-lib/src/components/organisms/ManageItems/ManageItemSlideOut'
import { LibItemTemplate } from 'theorem-lib/src/entities/types/Spaces'
import { CreateItemTemplateInput } from '../../../generated/api'
import { useActions, useAppState } from '../../../presenter'

export const CreateItemSlideOut = () => {
  const { createItemTemplate, displayModalAction, displaySlideOutAction, resetCurrentSpaceTemplate } = useActions()
  const { itemCategories } = useAppState()

  const newItem: LibItemTemplate = {
    highPrice: 0,
    id: '',
    lowPrice: 0,
    midPrice: 0,
    name: '',
    quantity: 0,
  }

  const onClose = () => {
    displaySlideOutAction('None')
    resetCurrentSpaceTemplate()
  }

  const onDiscard = () => {
    displayModalAction('GenericCancelModal')
  }

  const onSubmit = async (newItem: LibItemTemplate) => {
    const { createdOn, id, itemTemplatesId, modifiedOn, quantity, ...prunedItem } = newItem

    const createdInput: CreateItemTemplateInput = {
      ...prunedItem,
      category: newItem?.category?.id || '',
    }

    await createItemTemplate(createdInput)
    resetCurrentSpaceTemplate()
    displaySlideOutAction('None')
  }

  return (
    <LibSlideOut
      item={newItem}
      totalCategories={itemCategories}
      onClose={onClose}
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      isCreate={true}
      isTheorem={true}
    />
  )
}
