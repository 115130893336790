import React from 'react'
import { AddUserSlideOut as LibSlideOut, InviteUser } from 'theorem-lib/src/components/organisms/Users/AddUserSlideOut'
import { useActions } from '../../../presenter'

export const AddUserSlideOut = () => {
  const {
    displayModalAction,
    displaySlideOutAction,
    submitInviteNewUserAction,
  } = useActions()

  const onClose = () => {
    displaySlideOutAction('None')
  }

  const onDiscard = () => {
    displayModalAction('GenericCancelModal')
  }

  const onSubmit = (newUser: InviteUser) => {
    submitInviteNewUserAction({ email: newUser.email, firstName: newUser.firstName })
    displaySlideOutAction('None')
  }

  return (
    <LibSlideOut
      onSubmit={onSubmit}
      onClose={onClose}
      onDiscard={onDiscard}
    />
  )
}
