import { gql } from '@apollo/client'

export const getSpaceItemTemplatesDocument = gql `
query GetSpaceItemTemplates {
    getSpaceItemTemplates {
      id,
      name,
      category {
          id,
        name,
        createdOn,
        modifiedOn,
      },
      lowPrice,
      midPrice,
      highPrice,
      createdOn,
      modifiedOn,
      image,
      quantity,
  }
}
`
export const getSpaceItemTemplateByIdDocument = gql `
query GetSpaceItemTemplateById($id: ID!) {
	getSpaceItemTemplateById(templateId: $id) {
    id,
    name,
    highPrice,
    lowPrice,
    midPrice,
    modifiedOn,
    createdOn,
    image,
    category {
      id,
      name
    }
  }
}
`

export const getItemCategoriesDocument = gql `
query {
  getItemTemplateCategories {
    id,
    name
  }
}
`

export const createItemTemplateDocument = gql `
mutation CreateItemTemplate($input: CreateItemTemplateInput!) {
  createItemTemplate(input: $input)
}
`

export const GetSpaceItemTemplatesDocument = gql `
  query GetSpaceItemTemplates {
    getSpaceItemTemplates {
      id
      name
      category {
        id
        name
        createdOn
        modifiedOn
      }
      lowPrice
      midPrice
      highPrice
      createdOn
      modifiedOn
      image
      quantity
    }
  }
`

export const DeleteSpaceItemTemplateDocument = gql `
  mutation DeleteItemTemplate($id: ID!) {
    deleteItemTemplate(id: $id)
  }
`

export const UpdateSpaceItemTemplateDocument = gql `
  mutation UpdateItemTemplate($input: UpdateItemTemplateInput!) {
    updateItemTemplate(input: $input)
  }
`
