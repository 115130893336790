import React from 'react'
import { Modal } from 'theorem-lib/src/components/molecules/Modal/Modal'

export type GenericDeleteModalProps = {
  mainText?: string
  subText?: string
  onDelete: () => void
  onCancel: () => void
}

export function GenericDeleteModal(props: GenericDeleteModalProps) {
  return (
    <Modal
      isOpen={true}
      onClose={props.onCancel}
    >
      <div className='flex flex-col justify-center items-center w-[80vw] sm:w-[558px]'>
        <div className='text-primary-100 text-xl font-semibold mb-2 text-center'>
          {props.mainText ?? 'Are you sure you want to delete?'}
        </div>
        <div className='text-primary-300 text-base font-normal text-center mb-6'>
          {props.subText ?? 'This action cannot be undone'}
        </div>
        <div className='flex flex-row'>
          <button type='button' className='button mx-2' onClick={props.onCancel}>Cancel</button>
          <button
            type='button'
            className='button-no-bg-color bg-teal-300 hover:bg-teal-400 text-white mx-2'
            onClick={props.onDelete}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  )
}
