import { ApolloError } from '@apollo/client'
import { cloneDeep } from 'lodash'
import config from '../config'
import { Tenant, UpdateTenantDetailsInput } from '../generated/api'
import { UnexpectedError } from '../presenter/errors'
import { GetTenants, UpdateTenantDocument } from './documents/tenants.document'
import { createApolloClient, graphqlErrorHandler } from './utils'

const client = createApolloClient(config.theoremGraphqlUrl)

export const getTenants = async (): Promise<Array<Tenant>> => {
  try {
    const { data } = await client.query({
      fetchPolicy: 'no-cache',
      query: GetTenants,
    })
    return cloneDeep(data.getTenants)
  } catch (err: unknown | Error | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}

export const updateTenant = async (input: UpdateTenantDetailsInput): Promise<Tenant> => {
  try {
    const ret = await client.mutate({
      mutation: UpdateTenantDocument,
      variables: {
        input,
      },
    })
    return ret.data.updateTenant
  } catch (err: unknown | Error | ApolloError) {
    graphqlErrorHandler(err)
    throw new UnexpectedError()
  }
}
